/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EmailIcon, EmailShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import Logo from '../../assets/images/bahanno-logo.svg';
import YtIcon from '../../assets/images/yt.svg';
import InitInfo from '../utils/InitInfo';

const SocialMediaLink = ({ fb, tt, yt }) => (
    <div className="details-social">
        <ul>
            <li>
                <a href={fb} target="_blank" rel="noreferrer">
                    <FacebookIcon size={32} round />
                </a>
            </li>
            <li>
                <a href={tt} target="_blank" rel="noreferrer">
                    <TwitterIcon size={32} round />
                </a>
            </li>
            <li>
                <a href={yt} target="_blank" rel="noreferrer">
                    <img src={YtIcon} width={32} round alt="Yt Icon" />
                </a>
            </li>
            <li>
                <EmailShareButton
                    url="https://bahannonews.com/"
                    className="Demo__some-network__share-button"
                >
                    <EmailIcon size={32} round />
                </EmailShareButton>
            </li>
        </ul>
    </div>
);
export default function TopMenuModal({ show, handleClose }) {
    const { configs, categoryAr } = InitInfo();
    // console.log(categoryAr.online);
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const tDay = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
        date < 10 ? `0${date}` : `${date}`
    }`;
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
            className="top-menu-modal-content"
            closeButton="closeClass"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <a href="/">
                        <img src={Logo} alt="Logo" style={{ width: 150 }} />
                    </a>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body class="top-menu-modal-body">
                <div className="row">
                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(0, 4).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(4, 8).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>

                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(8, 12).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(12, 13).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            {categoryAr.online ? (
                                <li key={categoryAr.online[15].page_id} className="link-area">
                                    <Link
                                        to={categoryAr.online[15].url || '/'}
                                        className="link-title"
                                    >
                                        {categoryAr.online[15].title}
                                    </Link>
                                </li>
                            ) : (
                                ''
                            )}
                            {categoryAr.online &&
                                categoryAr.online.slice(16, 17).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            {categoryAr.online &&
                                categoryAr.online.slice(13, 15).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col top-m-menu-col">
                        <ul>
                            <li className="link-area" key={4}>
                                <Link to="/gallery/" className="link-title">
                                    ফটো গ্যালারি{' '}
                                </Link>
                            </li>
                            <li className="link-area" key={5}>
                                <Link to="/" className="link-title">
                                    ভিডিও গ্যালারি{' '}
                                </Link>
                            </li>
                            <li className="link-area" key={6}>
                                <Link to={`/archive/${tDay}`} className="link-title">
                                    আর্কাইভ
                                </Link>
                            </li>
                            <li className="link-area" key={7}>
                                <a
                                    href="https://converter.bahannonews.com/"
                                    className="link-title"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    বাংলা কনভার্টার
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SocialMediaLink
                    fb={configs.facebook}
                    tt={configs.twitter}
                    li={configs.linkedin}
                    yt={configs.youtube}
                />
            </Modal.Footer>
        </Modal>
    );
}
