import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/PlayButton';
import Iopt from '../../../utils/Iopt';

export default function SideBarNewsWithLeadEducation({ title, articleAr, articleArLead }) {
    if (!articleAr || !articleArLead) {
        return null;
    }

    return (
        <div className="sidebar-category-news">
            <div className="sidebar-category-news-title-edu">
                <div className="sidebar-category-news-title-edu-sub">
                    <Link to={`/${articleArLead.category}/${articleArLead.page}/`}>{title}</Link>
                </div>
            </div>

            {Object.values(articleAr)
                .sort((item1, item2) => {
                    if (item1.time_published > item2.time_published) {
                        return -1;
                    }
                    if (item1.time_published < item2.time_published) {
                        return 1;
                    }

                    return 0;
                })
                .slice(0, 3)
                .map((row) => (
                    <Link to={row.link} className="article-list-item link-area" key={row.sl}>
                        <div className="row h-100 gx-2">
                            <div className="col-4 align-self-center">
                                <div className="image-area">
                                    <img src={Iopt(row.image, 120)} alt="im" />
                                    <PlayButton show={row.having_video} />
                                </div>
                            </div>
                            <div className="col-8 align-self-center">
                                <div className="text-area">{row.title}</div>
                            </div>
                        </div>
                    </Link>
                ))}

            <div className="category-news-footer">
                <div className="link-area">
                    <Link
                        to={`/${articleArLead.category}/${articleArLead.page}/`}
                        className="category-news-footer-text link-title"
                    >
                        আরও...
                    </Link>
                </div>
            </div>
        </div>
    );
}
