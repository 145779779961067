/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/PlayButton';
import Iopt from '../../../utils/Iopt';

export default function SideBarNewsWithLead({ title, articlesAr }) {
    if (!articlesAr) {
        return null;
    }
    const articleArLead = articlesAr[0];

    return (
        <div className="sidebar-category-news">
            <div
                className={`${
                    articleArLead.page === 'exclusive'
                        ? 'sidebar-category-news-title-ex'
                        : 'sidebar-category-news-title'
                }`}
            >
                <div
                    className={`${
                        articleArLead.page === 'exclusive'
                            ? 'sidebar-category-news-title-ex-sub'
                            : 'sidebar-category-news-title-sub'
                    }`}
                >
                    <Link to={`/${articleArLead.category}/${articleArLead.page}/`}>{title}</Link>
                </div>
            </div>

            <Link to={articleArLead.link} className="side-news-short link-area link-area-ex">
                <div className="side-news-short-img image-area">
                    <img src={Iopt(articleArLead.image, 360)} alt="news" />
                    <PlayButton show={articleArLead.having_video} />
                </div>
                <div className="side-news-short-title link-title-ex">{articleArLead.title}</div>
            </Link>

            <hr style={{ marginTop: 0 }} />
            {Object.values(articlesAr)
                .sort((item1, item2) => {
                    if (item1.time_published > item2.time_published) {
                        return -1;
                    }
                    if (item1.time_published < item2.time_published) {
                        return 1;
                    }

                    return 0;
                })
                .slice(1, 5)
                .map((row) => (
                    <Link
                        to={row.link}
                        className="update-news-body link-area link-area-ex"
                        key={row.sl}
                    >
                        <div className="row ml-0 mr-2 mb-3">
                            <div className="col-4">
                                <div className="update-news-body-img image-area">
                                    <img src={Iopt(row.image, 120)} alt="im" />
                                    <PlayButton show={row.having_video} />
                                </div>
                            </div>
                            <div className="col-8 pl-0 update-news-top-bottom-margin-auto">
                                <div className="update-news-body-title link-title-ex">
                                    {row.title}
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}

            <div className="category-news-footer">
                <div className="link-area">
                    <Link
                        to={`/${articleArLead.category}/${articleArLead.page}/`}
                        className="category-news-footer-text link-title"
                    >
                        আরও...
                    </Link>
                </div>
            </div>
        </div>
    );
}
