const initialState = {};

const ActiveMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ACTIVE_MENU_SET': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default ActiveMenuReducer;
