import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import FooterSectionSecond from '../../system/section/FooterSectionSecond';
import HeaderSectionSecond from '../../system/section/HeaderSectionSecond';
import MenuSection from '../../system/section/MenuSection';
import UserSidebarSection from '../section/UserSidebarSection';

function UserAuthHandler() {
    const { currentUser, isSignIn } = useAuth();
    // const browserInfo = BrowserInfo();

    if (currentUser === null) {
        // return <div>Please Login</div>;
        return <div>Loading User Information ...</div>;
    }

    if (!isSignIn) {
        return <div>Sign In First</div>;
    }

    return (
        <>
            <HeaderSectionSecond />
            <MenuSection />
            <div className="container">
                <div className="row">
                    <UserSidebarSection />
                    <Outlet />
                </div>
            </div>

            <FooterSectionSecond />
        </>
    );
}

export default UserAuthHandler;
