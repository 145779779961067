import React from 'react';

function PlayButton({ show }) {
    if (!show) {
        return null;
    }

    return <div className="play-button" />;
}

export default PlayButton;
