import React from 'react';
import { Link } from 'react-router-dom';
import userIcon from '../../../../assets/images/user-edit-solid.svg';
import Iopt from '../../../utils/Iopt';

export default function SidebarOpinion({ title, articleAr, col = '', maxArticle = 5 }) {
    if (!articleAr) {
        return null;
    }

    const articleSls = Object.values(articleAr);

    return (
        <div className="sidebar-category-news">
            <div className={col || 'sidebar-category-news-title'}>
                <div className="sidebar-category-news-title-sub">
                    <Link
                        to={
                            articleSls.length > 0
                                ? `/${articleSls[0].category}/${articleSls[0].page}/`
                                : '/'
                        }
                    >
                        {title}
                    </Link>
                </div>
            </div>

            {Object.values(articleAr)
                .sort((item1, item2) => {
                    if (item1.time_published > item2.time_published) {
                        return -1;
                    }
                    if (item1.time_published < item2.time_published) {
                        return 1;
                    }

                    return 0;
                })
                .slice(0, maxArticle)
                .map((row) => (
                    <Link
                        to={row.link || '/'}
                        className="article-item-opinion link-area"
                        key={row.sl}
                    >
                        <div className="row">
                            <div className="col-auto align-self-center">
                                <div className="image-area">
                                    <img src={Iopt(row.image, 240)} alt="Im" />
                                </div>
                            </div>
                            <div className="col p-0">
                                <div className="link-title">{row.title}</div>
                                <div className="summery">{row.summery}</div>
                            </div>
                            <div className="col-12 mt-2 d-flex">
                                <img src={userIcon} alt="User" className="author-img" />
                                <b className="author">{row.author}</b>
                            </div>
                        </div>
                    </Link>
                ))}
            <div className="category-news-footer">
                <div className="link-area">
                    <Link
                        to={
                            articleSls.length > 0
                                ? `/${articleSls[0].category}/${articleSls[0].page}/`
                                : '/'
                        }
                        className="category-news-footer-text link-title"
                    >
                        আরও...
                    </Link>
                </div>
            </div>
        </div>
    );
}
