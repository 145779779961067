import React from 'react';
import HeroNews from '../../../components/HeroNews';
import LatestAndTopNews from '../../../components/LatestAndTopNews';

export default function HeroAndUpdatedNews({ heroNewsArticle, latestArticles, popularArticles }) {
    return (
        <div className="row">
            <div className="col-12 col-510 ">
                <HeroNews article={heroNewsArticle} />
            </div>
            <div className="col-lg-6 col-xl-4 col-12 col-300 d-none d-md-block">
                <div className="d-none d-md-block">
                    <LatestAndTopNews
                        latestArticles={latestArticles}
                        popularArticles={popularArticles}
                        maxArticle={5}
                    />
                </div>
            </div>
        </div>
    );
}
