import { Link } from 'react-router-dom';
import PickCategoryByPageId from '../../../models/PickCategoryByPageId';
import BoxNewsDetail from '../components/BoxNewsDetail';

function DetailsSection02({ article, speArticleAr = [] }) {
    // const speArticleArLead = Object.values(speArticleAr)[1];
    const cat = PickCategoryByPageId('online', article.page);

    return (
        <div className="row not-print-class">
            {/* <div className="col-12">
                <Ads14Full show mode="placeholder" />
            </div> */}
            <div className="col-12 details-realted-news-title">এই সম্পর্কিত আরও সাংবাদ </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-lg-4 col-xl-4 col-12 ">
                        <BoxNewsDetail article={Object.values(speArticleAr)[1]} />
                    </div>
                    <div className="col-lg-4 col-xl-4 col-12">
                        <BoxNewsDetail article={Object.values(speArticleAr)[2]} />
                    </div>
                    <div className="col-lg-4 col-xl-4 col-12">
                        <BoxNewsDetail article={Object.values(speArticleAr)[3]} />
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="link-area">
                    <Link to={cat.url || '/'} style={{ float: 'right' }} className="link-title">
                        আরও...
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default DetailsSection02;
