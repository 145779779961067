import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';
import { setActiveMenu } from '../utils/Menu';

// function categoryInitSet(payload) {
//     return {
//         type: 'CATEGORY_INIT_SET',
//         payload,
//     };
// }

// function latestInitSet(payload) {
//     return {
//         type: 'LATEST_INIT_SET',
//         payload,
//     };
// }

function initDataSetup(payload) {
    return {
        type: 'INIT_DATA_SET',
        payload,
    };
}

function siteDataSetup(payload) {
    return {
        type: 'SITE_DATA_SET',
        payload,
    };
}

// function configInit(payload) {
//     return {
//         type: 'CONFIG_SET',
//         payload,
//     };
// }

// function welcomeBannerSet(payload) {
//     return {
//         type: 'WELCOME_BANNER_SET',
//         payload,
//     };
// }

export default function AppInitDispatch(dispatch, params) {
    return new Promise((resolve, reject) => {
        if (!store.getState().initData?.initId || params?.reset === true) {
            AxiosAuth.post(ConfigApi.API_APP_INIT, params)
                .then((response) => {
                    // console.log(response.data.categoryHomeIds);
                    // Init Info
                    localStorage.setItem('init-id', response.data.initData.initId);
                    dispatch(initDataSetup(response.data.initData));
                    dispatch(siteDataSetup(response.data.siteData));

                    // dispatch(categoryInitSet(response.data.categoryAr));
                    // dispatch(latestInitSet(response.data.lastUpdate));
                    // dispatch(configInit(response.data.configs));
                    // dispatch(welcomeBannerSet(response.data.wcBanner));
                    // dispatch(welcomeBannerSet(response.data.wcBanner));

                    dispatch(setActiveMenu('/'));
                    localStorage.setItem('showTopAd', 'block');
                    localStorage.setItem('showBottomAd', true);
                    resolve(true);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        } else {
            resolve(true);
        }
    });
}
