import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Iopt from '../utils/Iopt';
import PlayButton from './PlayButton';

function ArticleLink({ row }) {
    return (
        <Link to={row.link} className="article-list-item link-area">
            <div className="row h-100 gx-2">
                <div className="col-4 align-self-center">
                    <div className="image-area">
                        <img src={Iopt(row.image, 120)} alt="im" />
                        <PlayButton show={row.having_video} />
                    </div>
                </div>
                <div className="col-8 align-self-center">
                    <div className="text-area">{row.title}</div>
                </div>
            </div>
        </Link>
    );
}

function ThreeTabNews({ articles1, title1, articles2, title2, articles3, title3 }) {
    const [tabIndex, setTabIndex] = useState(0);

    // active
    return (
        <div className="updated-news pt-0">
            <div className="update-news-title">
                <button
                    type="button"
                    className={tabIndex === 0 ? 'active' : ''}
                    onClick={() => {
                        setTabIndex(0);
                    }}
                >
                    {title1}
                </button>
                <button
                    type="button"
                    className={tabIndex === 1 ? 'active' : ''}
                    onClick={() => {
                        setTabIndex(1);
                    }}
                >
                    {title2}
                </button>
                <button
                    type="button"
                    className={tabIndex === 2 ? 'active' : ''}
                    onClick={() => {
                        setTabIndex(2);
                    }}
                >
                    {title3}
                </button>
            </div>

            <div
                className={tabIndex === 0 ? 'display-show update-news-body-scroll' : 'display-hide'}
                style={{ height: '417px' }}
            >
                {Object.values(articles1)
                    .sort((item1, item2) => {
                        if (item1.time_published > item2.time_published) {
                            return -1;
                        }
                        if (item1.time_published < item2.time_published) {
                            return 1;
                        }

                        return 0;
                    })
                    .slice(0, 5)
                    .map((row) => (
                        <ArticleLink row={row} key={row.sl} />
                    ))}

                <div style={{ borderTop: '1px solid #e5e5e5', overflow: 'hidden' }}>
                    <div className="link-area">
                        <Link to="/online/sports/" className="category-news-footer-text link-title">
                            আরও...
                        </Link>
                    </div>
                </div>
            </div>

            <div
                className={tabIndex === 1 ? 'display-show update-news-body-scroll' : 'display-hide'}
                style={{ height: '417px' }}
            >
                {Object.values(articles2)
                    .sort((item1, item2) => {
                        if (item1.time_published > item2.time_published) {
                            return -1;
                        }
                        if (item1.time_published < item2.time_published) {
                            return 1;
                        }

                        return 0;
                    })
                    .slice(0, 5)
                    .map((row) => (
                        <ArticleLink row={row} key={row.sl} />
                    ))}
                <div style={{ borderTop: '1px solid #e5e5e5', overflow: 'hidden' }}>
                    <div className="link-area">
                        <Link
                            to="/online/entertainment/"
                            className="category-news-footer-text link-title"
                        >
                            আরও...
                        </Link>
                    </div>
                </div>
            </div>

            <div
                className={tabIndex === 2 ? 'display-show update-news-body-scroll' : 'display-hide'}
                style={{ height: '417px' }}
            >
                {Object.values(articles3)
                    .sort((item1, item2) => {
                        if (item1.time_published > item2.time_published) {
                            return -1;
                        }
                        if (item1.time_published < item2.time_published) {
                            return 1;
                        }

                        return 0;
                    })
                    .slice(0, 5)
                    .map((row) => (
                        <ArticleLink row={row} key={row.sl} />
                    ))}
                <div style={{ borderTop: '1px solid #e5e5e5', overflow: 'hidden' }}>
                    <div className="link-area">
                        <Link to="/online/jobs/" className="category-news-footer-text link-title">
                            আরও...
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThreeTabNews;
