import React from 'react';
import BrowserInfo from '../../../utils/BrowserInfo';
import SideBarNewsList from '../components/SideBarNewsList';
import SectionThumb from './SectionThumb';

function HomeSection05MainLg({ articles2, title2 }) {
    return (
        <div className="container">
            <SectionThumb title={title2} articleAr={articles2} />
        </div>
    );
}

function HomeSection05Main({ articles1, title1, articles2, title2 }) {
    return (
        <div className="container">
            <div className="row">
                <div className="col col-300-1">
                    <SideBarNewsList title={title1} articleAr={articles1} maxArticle={6} />
                </div>
                <div className="col col-840">
                    <SectionThumb title={title2} articleAr={articles2} />
                </div>
            </div>
        </div>
    );
}

function HomeSection05({ articles1, title1, articles2, title2 }) {
    const { sizeName } = BrowserInfo();

    if (sizeName === 'bs-lg') {
        return (
            <HomeSection05MainLg
                title1={title1}
                articles1={articles1}
                title2={title2}
                articles2={articles2}
            />
        );
    }

    return (
        <HomeSection05Main
            title1={title1}
            articles1={articles1}
            title2={title2}
            articles2={articles2}
        />
    );
}

export default HomeSection05;
