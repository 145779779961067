import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import ListCountry from '../../../utils/ListCountry';

function UserProfilePage() {
    const { currentUser } = useAuth();
    const [isReadOnly, setIsReadOnly] = useState(true);
    const refName = useRef();
    const refPhone = useRef();
    const refEmail = useRef();
    const refBdate = useRef();
    const refCountry = useRef();
    const handleChangeCountry = (ev) => {
        refCountry.current.value = ev.target.value;
    };
    const { setMessage } = useTemplate();

    const handleSubmit = () => {
        setMessage('Loading...');

        AxiosAuth.post(ConfigApi.API_USER_UPDATE, {
            email: refEmail.current.value,
            name: refName.current.value,
            phone: refPhone.current.value,
            country: refCountry.current.value,
            bDdate: refBdate.current.value,
        })
            .then((response) => {
                console.log(response.data.error);
                if (response.data.error === 0) {
                    console.log('ok');
                    setMessage(response.data.message);
                    setIsReadOnly(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        if (currentUser) {
            refName.current.value = currentUser?.displayName || '';
            refPhone.current.value = currentUser?.phoneNumber || '';
            refEmail.current.value = currentUser?.email || '';
            refBdate.current.value = currentUser?.email || '';
            refCountry.current.value = 'BD';
        }
    }, [currentUser]);

    return (
        <div className="col col-840">
            <div className="user-profile-title">
                <h4>My Profile</h4>
                <button type="button" onClick={() => setIsReadOnly(false)}>
                    Edit Profile
                </button>
            </div>
            <hr />
            <div className="user-profile-info">
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                ref={refName}
                                type="text"
                                readOnly={isReadOnly}
                                placeholder="Enter Full Name"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                ref={refEmail}
                                type="email"
                                readOnly
                                placeholder="Enter email"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                ref={refPhone}
                                type="text"
                                readOnly={isReadOnly}
                                placeholder="Enter Phone Number"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                                ref={refBdate}
                                type="date"
                                readOnly={isReadOnly}
                                placeholder="Enter Phone Number"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Country</Form.Label>
                            <ListCountry
                                def={refCountry}
                                readOnly={isReadOnly}
                                handleChange={handleChangeCountry}
                            />
                        </Form.Group>
                    </div>
                    {!isReadOnly ? (
                        <div className="col-12">
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="user-profile-update-btn"
                            >
                                Update
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserProfilePage;
