import { combineReducers } from 'redux';
import ActiveMenuReducer from './reducers/ActiveMenuReducer';
import InitSetReducer from './reducers/InitSetReducer';
import SiteSetReducer from './reducers/SiteSetReducer';
import WelcomeBannerReducer from './reducers/WelcomeBannerReducer';

const rootReducer = combineReducers({
    initData: InitSetReducer,

    siteData: SiteSetReducer,

    welcomeBanner: WelcomeBannerReducer,

    activeMenu: ActiveMenuReducer,

    // topAdd: TopAddReducer,

    // categoryAr: CategorySetReducer,

    // lastUpdate: LastUpdateSetReducer,

    // articleDetail: ArticleDetailsReducer,

    // configs: ConfigReducer,
});

export default rootReducer;
