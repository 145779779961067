import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '../../../../assets/images/search-solid.svg';
import { Ads14Full } from '../../../components/Ads';
import InitInfo from '../../../utils/InitInfo';
import BoxNews from '../components/BoxNews';

export default function ArchiveSection({ articles, date }) {
    const { categoryAr } = InitInfo();
    const totalCat = articles.length;

    return (
        <div className="col col-840">
            <div className="row">
                {totalCat > 0 ? (
                    <>
                        <div className="col-12">
                            <div className="home-category-news-title">
                                <h5>
                                    {`"${date}"`} তারিখ এর আর্কাইভে {totalCat} টি খবর পাওয়া গেছে
                                </h5>
                            </div>
                        </div>
                        <div className="col-12">
                            <Ads14Full show mode="placeholder" />
                        </div>
                    </>
                ) : null}

                {categoryAr.online?.map((cat) => {
                    const archiveDataAr = Object.values(articles).filter(
                        (e) => e.page === cat.page_id
                    );

                    return (
                        <Fragment key={cat.page_id}>
                            {archiveDataAr.length > 0 ? (
                                <div className="col-12">
                                    <div className="home-category-news-title">
                                        <div className="home-category-news-title-sub">
                                            <Link to="...">{cat.title}</Link>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            {archiveDataAr?.map((art) => (
                                <div className="col-lg-4 col-xl-4 col-12" key={art.sl}>
                                    <BoxNews article={art} />
                                </div>
                            ))}
                        </Fragment>
                    );
                })}
                {!articles ? (
                    <div className="search-data-n-found">
                        <div className="search-data-n-found-h">
                            অনুসন্ধানের কোনো ফলাফল পাওয়া যায়নি
                        </div>
                        <div className="search-data-n-found-b">
                            আমরা দুঃখিত. আমরা আপনার অনুসন্ধান শব্দটির জন্য কোন মিল খুঁজে পাচ্ছি না।
                        </div>
                        <div className="search-data-n-found-i">
                            <img src={SearchIcon} style={{ width: 50 }} alt="Search" />
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <div className="col-12">
                    <Ads14Full show mode="placeholder" />
                </div>
            </div>
        </div>
    );
}
