import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import PageProperties from '../../../models/PageProperties';
import IndexPagePlaceholder from '../../../placeholders/IndexPagePlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/Menu';
import HomeSection01 from '../section/HomeSection01';
import HomeSection02 from '../section/HomeSection02';
import HomeSection03 from '../section/HomeSection03';
import HomeSection04 from '../section/HomeSection04';
import HomeSection05 from '../section/HomeSection05';
import HomeSection06 from '../section/HomeSection06';
import HomeSection07 from '../section/HomeSection07';
import HomeSection08Lg from '../section/HomeSection08Lg';
import HomeSection09Lg from '../section/HomeSection09Lg';
import HomeSection10Md from '../section/HomeSection10Md';
import HomeSection11Md from '../section/HomeSection11Md';

function IndexPageControl() {
    const dispatch = useDispatch();
    // const { wcBanner } = InitInfo();
    const [indexArticles, setIndexArticles] = useState(null);

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'selected-lead': 10,
                'selected-latest': 5,
                'selected-special': 1,

                'category-online/opinion': 2,
                'category-online/education': 4,
                'category-online/national': 7,
                'category-online/politics': 6,
                'category-online/sports': 6,
                'category-online/crime': 6,
                'category-online/lifestyle': 5,
                'category-online/economics': 6,
                'category-online/international': 6,
                'category-online/bangladesh': 7,
                'category-online/health': 5,
                'category-online/entertainment': 6,
                'category-online/technology': 5,
                'category-online/exclusive': 5,
                'category-online/literature': 5,
                'category-online/expatriation': 5,

                gallery: 5,
            },
        }).then(({ data }) => {
            setIndexArticles(data.articles);
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(setActiveMenu('/'));
    }, [dispatch]);

    PageProperties('');

    if (indexArticles === null) {
        return <IndexPagePlaceholder />;
    }

    return (
        <>
            <HomeSection01
                speArticleAr={indexArticles['selected-special'] || []}
                leadArticles={indexArticles['selected-lead'] || []}
                popularArticles={[]}
                eduArticleAr={indexArticles['category-online/education'] || []}
                onlineOpinionArticleAr={indexArticles['category-online/opinion'] || []}
                latestArticles={indexArticles['selected-latest'] || []}
            />

            <HomeSection02
                nationalArticleAr={indexArticles['category-online/national'] || []}
                economicsArticleAr={indexArticles['category-online/economics'] || []}
            />

            <HomeSection08Lg
                articles1={indexArticles['category-online/politics']}
                title1="রাজনীতি"
                articles2={indexArticles['category-online/crime']}
                title2="আইন-আদালত"
                articles3={indexArticles['category-online/international']}
                title3="আন্তর্জাতিক"
            />

            <HomeSection10Md
                articles1={indexArticles['category-online/politics']}
                title1="রাজনীতি"
                articles2={indexArticles['category-online/crime']}
                title2="আইন-আদালত"
            />

            <HomeSection07
                articles1={indexArticles['category-online/crime']}
                title1="আইন-আদালত"
                articles2={indexArticles['category-online/sports']}
                title2="খেলাধুলা"
            />

            <HomeSection11Md
                articles1={indexArticles['category-online/international']}
                title1="আন্তর্জাতিক"
                articles2={indexArticles['category-online/economics']}
                title2="অর্থনীতি"
            />

            <HomeSection04
                articles1={indexArticles['category-online/bangladesh']}
                title1="সারাদেশ"
                articles2={indexArticles['category-online/international']}
                title2="আন্তর্জাতিক"
            />

            <HomeSection05
                articles1={indexArticles['category-online/economics']}
                title1="অর্থনীতি"
                articles2={indexArticles['category-online/entertainment']}
                title2="বিনোদন"
            />

            <HomeSection09Lg
                articles1={indexArticles['category-online/economics']}
                title1="অর্থনীতি"
                articles2={indexArticles['category-online/literature']}
                title2="শিল্প ও সাহিত্য"
                articles3={indexArticles['category-online/lifestyle']}
                title3="লাইফস্টাইল"
            />

            <HomeSection03
                articles1={indexArticles.gallery}
                title1="ফটো গ্যালারি"
                articles2={indexArticles['category-online/literature']}
                title2="শিল্প ও সাহিত্য"
            />

            <HomeSection06
                articles1={indexArticles['category-online/expatriation']}
                title1="প্রবাস"
                articles2={indexArticles['category-online/technology']}
                title2="বিজ্ঞান ও প্রযুক্তি"
                articles3={indexArticles['category-online/lifestyle']}
                title3="লাইফস্টাইল"
                articles4={indexArticles['category-online/health']}
                title4="স্বাস্থ্যসেবা"
            />

            {/*
            {wcBanner.id ? (
                <WelcomeModal show={!!wcBanner.id} localStorageName={`welcome-${wcBanner.id}`} />
            ) : null}
            */}
        </>
    );
}
export default function IndexPage() {
    const pathName = window.location.pathname;

    return <IndexPageControl key={pathName} />;
}
