/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Link } from 'react-router-dom';
import FooterEditPen from '../../../../assets/images/footer-edit-pen.svg';
import ReporterIcon from '../../../../assets/images/icon.png';
import { Ads08, Ads09 } from '../../../components/Ads';
import PickCategoryByPageId from '../../../models/PickCategoryByPageId';
import TimestampToDate from '../../../models/TimestampToDate';
import InitInfo from '../../../utils/InitInfo';

const CatLink = ({ category, pageId }) => {
    const cat = PickCategoryByPageId(category, pageId);
    let tBg = 'home-category-news-title';
    if (
        pageId === 'education' ||
        pageId === 'bangladesh' ||
        pageId === 'international' ||
        pageId === 'expatriation' ||
        pageId === 'technology' ||
        pageId === 'lifestyle' ||
        pageId === 'health'
    ) {
        tBg = 'home-category-news-title-green';
    }
    return (
        <div className={tBg}>
            <div className="home-category-news-title-sub">
                <Link to={cat.url || '/'}>{cat.title}</Link>
            </div>
        </div>
    );
};

const ArticleTitle = ({ title }) => <div className="details-title">{title}</div>;

const ArticleInformationAndShare = ({ article, configs, articleDet }) => {
    const articleLink = `${configs.frontUrl}/details/article/${article.sl}/`;
    // const articleLink = `${configs.frontUrl}${article.link}`;
    // console.log(article);

    const AuthorImage = articleDet.author_image
        ? configs.frontUrl + articleDet?.author_image
        : ReporterIcon;

    return (
        <div className="row gx-1 gx-lg-2 details-reporter-info">
            <div className="col-auto">
                <div className="details-reporter-img">
                    <img
                        src={AuthorImage}
                        style={{ width: 40, height: 40, borderRadius: 100 }}
                        alt=""
                    />
                </div>
            </div>

            <div className="col">
                <div className="details-reporter-type">
                    <img src={FooterEditPen} alt="" className="mr-3" />

                    {article.author}
                </div>
                <hr className="details-reporter-divider" />
                <div className="details-report-time">
                    প্রকাশ : <TimestampToDate time={article.time_published} />
                </div>
            </div>
        </div>
    );
};

const LoadScripts = ({ details }) => {
    const regex = /<script.*?src="(.*?)"/gm;

    let x;
    // eslint-disable-next-line no-cond-assign
    while ((x = regex.exec(details.split('\n').join(' '))) !== null) {
        const url = x[1];
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
    }

    return null;
};

const mixAds = (details) => {
    const detailsAr = details.split('</p><p>');
    const ads08 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 col-lg-4">
                <Ads08 show />
            </div>
        </div>
    );
    detailsAr.splice(2, 0, ads08);

    const ads09 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 col-lg-4">
                <Ads09 show />
            </div>
        </div>
    );
    detailsAr.splice(6, 0, ads09);
    return detailsAr.join('</p><p>');
};

const ArticleBody = ({ article }) => {
    const { details } = article;
    const ref = useRef(null);

    useEffect(() => {
        if (ref?.current && details) {
            const links = ref.current.querySelectorAll('a');
            for (let x = 0; x < links.length; x += 1) {
                links[x].setAttribute('target', '_blank');
            }
        }
    }, [details]);

    return (
        <div className="details-news-body mb-4" ref={ref}>
            {article.details ? (
                <>
                    <div className="details-news-body-detail">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: details,
                                // __html: lightBox(details),
                            }}
                        />
                    </div>
                    <LoadScripts details={details} />
                </>
            ) : (
                <div className="loading-news">
                    <div className="load-wraper">
                        <div className="activity" />
                    </div>
                </div>
            )}
        </div>
    );
};

function FlowDetailSection({ article }) {
    const { configs } = InitInfo();
    const articleDet = article.details;
    const articleLink = `${configs.frontUrl}${article.link}`;

    return (
        <div className="flow-details-news">
            <div className="hero-news-detail">
                <ArticleTitle title={article.lt ? article.lt : article.title} />

                <ArticleInformationAndShare
                    article={article}
                    configs={configs}
                    articleDet={articleDet}
                />

                <ArticleBody article={{ ...article, ...articleDet }} />
            </div>
        </div>
    );
}

export default FlowDetailSection;
