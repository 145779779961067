import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/bahanno-logo.svg';
import SearchIcon from '../../assets/images/Search.svg';
import InitInfo from '../utils/InitInfo';

export default function SidebarMenu({ show, handleClose }) {
    const { categoryAr } = InitInfo();
    const inp = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const q = new URLSearchParams(location.search).get('q');
    const [searchKey, setSearchKey] = useState(q);
    const onSearchClick = (ev, mode) => {
        const moveToSearch = () => {
            navigate(`/search/?q=${encodeURIComponent(searchKey)}`);
        };

        if (mode === 'change') {
            setSearchKey(ev.target.value);
        }

        if (mode === 'keyup') {
            if (ev.keyCode === 13) {
                // On press enter
                moveToSearch();
            } else {
                setSearchKey(ev.target.value);
            }
        } else if (mode === 'searchClick') {
            moveToSearch();
        }
    };
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const tDay = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
        date < 10 ? `0${date}` : `${date}`
    }`;
    return (
        <Modal
            // className="modal left"
            // show={show}
            // onHide={handleClose}
            // backdrop="static"
            // keyboard={false}
            // dialogClassName="modal-dialog modal-sm"
            // animation={false}
            show={show}
            onHide={handleClose}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
            className="modal left "
        >
            <div className="modal-content">
                <Modal.Header
                    closeButton={
                        <button type="button" className="close btn btn-close">
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                    }
                >
                    <Modal.Title>
                        <img src={Logo} alt="Logo" style={{ width: 125 }} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div
                        className={`header-search-group ${
                            show ? 'header-search-bar2-show' : 'header-search-bar2-hide'
                        }`}
                    >
                        <div className="header-search-text-input">
                            <input
                                ref={inp}
                                placeholder="এখানে লিখুন..."
                                onKeyUp={(ev) => {
                                    onSearchClick(ev, 'keyup');
                                }}
                                value={searchKey || ''}
                                onChange={(ev) => {
                                    onSearchClick(ev, 'change');
                                }}
                            />
                        </div>
                        <button
                            type="button"
                            className="header-search-send"
                            onClick={(ev) => {
                                onSearchClick(ev, 'searchClick');
                            }}
                        >
                            <img src={SearchIcon} alt="Send Icon" />
                        </button>
                    </div>
                    <ul className="mobile-menu">
                        {categoryAr?.online?.map((rows) => (
                            <li key={rows.sl} className="link-area">
                                <Link to={rows.url} className="link-title">
                                    {rows.title}
                                </Link>
                            </li>
                        ))}
                        <li className="link-area" key={4}>
                            <Link to="/gallery/photo_gallery/" className="link-title">
                                ফটো গ্যালারি{' '}
                            </Link>
                        </li>
                        <li className="link-area" key={3}>
                            <Link to="/" className="link-title">
                                ভিডিও গ্যালারি{' '}
                            </Link>
                        </li>
                        <li className="link-area" key={6}>
                            <Link to={`/archive/${tDay}`} className="link-title">
                                আর্কাইভ
                            </Link>
                        </li>
                        <li className="link-area" key={7}>
                            <a
                                href="https://converter.bahannonews.com/"
                                className="link-title"
                                target="_blank"
                                rel="noreferrer"
                            >
                                বাংলা কনভার্টার
                            </a>
                        </li>
                    </ul>
                </Modal.Body>
            </div>
        </Modal>
    );
}
