import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { Link } from 'react-router-dom';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    // eslint-disable-next-line prettier/prettier
    WhatsappShareButton
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import copyIcon from '../../../../assets/images/copy-solid.svg';
import FooterEditPen from '../../../../assets/images/footer-edit-pen.svg';
import ReporterIcon from '../../../../assets/images/icon.png';
import ConfigApi from '../../../../configs/ConfigApi';
import { Ads08, Ads09 } from '../../../components/Ads';
import { useAuth } from '../../../contexts/AuthProvider';
import CommentProvider from '../../../contexts/CommentProvider';
import LightBoxProvider from '../../../contexts/LightBoxProvider';
import PickCategoryByPageId from '../../../models/PickCategoryByPageId';
import TimestampToDate from '../../../models/TimestampToDate';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import CommentSection from './CommentSection';
import DetailsSection02 from './DetailsSection02';

const CatLink = ({ category, pageId }) => {
    const cat = PickCategoryByPageId(category, pageId);
    let tBg = 'home-category-news-title';
    if (
        pageId === 'education' ||
        pageId === 'bangladesh' ||
        pageId === 'international' ||
        pageId === 'expatriation' ||
        pageId === 'technology' ||
        pageId === 'lifestyle' ||
        pageId === 'health'
    ) {
        tBg = 'home-category-news-title-green';
    }
    return (
        <div className={tBg}>
            <div className="home-category-news-title-sub">
                <Link to={cat.url || '/'}>{cat.title}</Link>
            </div>
        </div>
    );
};

function ShareDetailBox({ articleLink, article, myRef, show, setShow }) {
    const { configs } = InitInfo();
    const articleSortLink = `${configs.frontUrl}/sl/${article.sl}/`;
    const [tooltip, setTooltip] = useState('Copy Link');

    const handleCopy = () => {
        navigator.clipboard.writeText(articleLink);
        setTooltip('লিঙ্ক কপি হয়েছে');
        ReactTooltip.rebuild();
    };

    const handleLinkClick = () => {
        setShow(false);
    };

    return (
        <div ref={myRef} className="details-share-box" style={{ display: show ? 'block' : 'none' }}>
            <table className="details-share-box-table">
                <tbody>
                    <tr className="details-share-box-tr mb-4">
                        <td width="50%">
                            <FacebookShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <FacebookIcon size={32} round />
                                <span className="share-text fb-share-hover">
                                    ফেসবুকে শেয়ার করুন
                                </span>
                            </FacebookShareButton>
                        </td>
                        <td>
                            <FacebookMessengerShareButton
                                appId={configs.fbAppId}
                                url={articleSortLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <FacebookMessengerIcon size={32} round />
                                <span className="share-text me-share-hover">
                                    ম্যাসেঞ্জারে শেয়ার করুন
                                </span>
                            </FacebookMessengerShareButton>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            <PinterestShareButton
                                url={articleLink}
                                media={article.image}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <PinterestIcon size={32} round />
                                <span className="share-text pi-share-hover">
                                    পিন্টারিস্টে শেয়ার করুন
                                </span>
                            </PinterestShareButton>
                        </td>
                        <td>
                            <WhatsappShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <WhatsappIcon size={32} round />
                                <span className="share-text wh-share-hover">
                                    ওয়াটসঅ্যাপে শেয়ার করুন
                                </span>
                            </WhatsappShareButton>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            <TwitterShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <TwitterIcon size={32} round />
                                <span className="share-text tw-share-hover">
                                    টুইটারে শেয়ার করুন
                                </span>
                            </TwitterShareButton>
                        </td>
                        <td>
                            <LinkedinShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <LinkedinIcon size={32} round />
                                <span className="share-text li-share-hover">
                                    লিংকডইনে শেয়ার করুন
                                </span>
                            </LinkedinShareButton>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            <EmailShareButton
                                openShareDialogOnClick
                                onClick={handleLinkClick}
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onMouseMove={() => setTooltip('ইমেইল করুন')}
                                data-for="copyTip"
                            >
                                <EmailIcon size={32} round />
                                <span className="share-text em-share-hover">ইমেইল করুন</span>
                            </EmailShareButton>
                        </td>
                        <td>
                            <span
                                role="button"
                                tabIndex="-1"
                                data-for="copyTip"
                                onMouseMove={() => setTooltip('লিঙ্ক কপি করুন')}
                                data-tip
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    padding: 0,
                                }}
                                onClick={handleCopy}
                            >
                                <Button className="button-copy">
                                    <img
                                        src={copyIcon}
                                        alt="Copy Icon"
                                        style={{ width: 20, height: 18 }}
                                    />
                                </Button>
                                <span className="share-text co-share-hover">লিংক কপি করুন</span>
                            </span>
                            <ReactTooltip id="copyTip" place="top" effect="solid">
                                {tooltip}
                            </ReactTooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function SocialShare({ articleLink, article, configs }) {
    const [moreShareShow, setMoreShareShow] = useState(false);
    const [isSave, setIsSave] = useState(article.isSave);
    // const { setMessage } = useTemplate();
    const { currentUser } = useAuth();
    const handleMoreShare = () => {
        setMoreShareShow(true);
    };

    const ref = useRef();
    const handleClickOutside = (ev) => {
        if (!(ref && ref.current?.contains(ev.target))) {
            // alert('You clicked outside of me!');
            setMoreShareShow(false);
        }
    };
    const handleClickSave = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(`${ConfigApi.API_SAVE}`, { articleSl: article.sl })
            .then(() => {
                setIsSave(1);
            });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        setIsSave(article.isSave);
    }, [article.isSave]);

    return (
        <>
            <ul>
                <li>
                    <FacebookShareButton url={articleLink} className="details-share-fb" />
                </li>
                <li>
                    <TwitterShareButton url={articleLink} className="details-share-tw" />
                </li>
                <li>
                    <EmailShareButton
                        openShareDialogOnClick
                        url={articleLink}
                        className="details-share-email"
                    />
                </li>
                <li>
                    <Button className="details-share-more" onClick={handleMoreShare} />
                </li>
                <li>
                    <Button
                        className={
                            isSave === 1 ? 'details-share-save-active' : 'details-share-save'
                        }
                        onClick={() => handleClickSave()}
                    />
                </li>
                <li>
                    <Link
                        className="details-share-printer"
                        to={`${article.link}print/`}
                        onClick={(ev) => {
                            ev.preventDefault();
                            window.print();
                        }}
                        target="_blank"
                    />
                </li>
            </ul>
            <ShareDetailBox
                articleLink={articleLink}
                show={moreShareShow}
                setShow={setMoreShareShow}
                myRef={ref}
                configs={configs}
                article={article}
            />
        </>
    );
}

const ArticleInformationAndShare = ({ article, configs, articleDet }) => {
    const articleLink = `${configs.frontUrl}/details/article/${article.sl}/`;

    const AuthorImage = articleDet.author_image
        ? configs.frontUrl + articleDet?.author_image
        : ReporterIcon;

    return (
        <div className="row gx-1 gx-lg-2 details-reporter-info">
            <div className="col-auto">
                <div className="details-reporter-img">
                    <img
                        src={AuthorImage}
                        style={{ width: 40, height: 40, borderRadius: 100 }}
                        alt=""
                    />
                </div>
            </div>

            <div className="col">
                <div className="details-reporter-type">
                    <img src={FooterEditPen} alt="" className="mr-3" />

                    {article.author}
                </div>
                <hr className="details-reporter-divider" />
                <div className="details-report-time">
                    প্রকাশ : <TimestampToDate time={article.time_published} />
                </div>
            </div>

            <div className="col-12 col-md-auto">
                <div className="details-social">
                    <SocialShare article={article} articleLink={articleLink} configs={configs} />
                </div>
            </div>
        </div>
    );
};

const LoadScripts = ({ details }) => {
    const regex = /<script.*?src="(.*?)"/gm;

    let x;
    // eslint-disable-next-line no-cond-assign
    while ((x = regex.exec(details.split('\n').join(' '))) !== null) {
        const url = x[1];
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
    }

    return null;
};

const mixAds = (details) => {
    const detailsAr = details.split('</p><p>');
    const ads08 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 col-lg-4">
                <Ads08 show />
            </div>
        </div>
    );
    detailsAr.splice(2, 0, ads08);

    const ads09 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 col-lg-4">
                <Ads09 show />
            </div>
        </div>
    );
    detailsAr.splice(6, 0, ads09);
    return detailsAr.join('</p><p>');
};

const ArticleBody = ({ article }) => {
    const ref = useRef(null);
    const { details } = article;

    useEffect(() => {
        if (ref?.current && details) {
            const links = ref.current.querySelectorAll('a');
            for (let x = 0; x < links.length; x += 1) {
                links[x].setAttribute('target', '_blank');
            }
        }
    }, [details]);

    return (
        <div className="details-news-body mb-4" ref={ref}>
            {article.details ? (
                <LightBoxProvider>
                    <div className="details-news-body-detail">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: mixAds(details),
                                // __html: lightBox(details),
                            }}
                        />
                    </div>
                    <LoadScripts details={details} />
                </LightBoxProvider>
            ) : (
                <div className="loading-news">
                    <div className="load-wraper">
                        <div className="activity" />
                    </div>
                </div>
            )}
        </div>
    );
};

function DetailsSection01({ article = null, isHeaderTitle = true }) {
    const { configs } = InitInfo();
    const articleDet = article.details;
    const articleLink = `${configs.frontUrl}${article.link}`;
    const { currentUser } = useAuth();
    const relatedArticle = article.articles_related;

    return (
        <>
            <div className="header-news">
                <div className="hero-news-detail">
                    {isHeaderTitle ? (
                        <CatLink category={article.category} pageId={article.page} />
                    ) : (
                        ''
                    )}
                    <h1 className="details-title">{article.lt ? article.lt : article.title}</h1>

                    <ArticleInformationAndShare
                        article={article}
                        configs={configs}
                        articleDet={articleDet}
                    />

                    <ArticleBody article={{ ...article, ...articleDet }} />

                    <div className="row gx-1 gx-lg-2">
                        <div className="col-auto">&nbsp;</div>

                        <div className="col">&nbsp;</div>

                        <div className="col-12 col-md-auto">
                            <div className="details-social">
                                <SocialShare
                                    article={article}
                                    articleLink={articleLink}
                                    configs={configs}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <CommentProvider id={article.sl} currentUser={currentUser}>
                            <CommentSection />
                        </CommentProvider>
                    </div>
                </div>

                <div className="print-footer" style={{ paddingLeft: '30%' }}>
                    <div
                        className="details-reporter-img"
                        style={{ display: 'flex', width: '200px' }}
                    >
                        <img
                            src={ReporterIcon}
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 100,
                                marginTop: 15,
                                marginRight: 10,
                            }}
                            alt=""
                        />
                        <h3 style={{ marginTop: 20 }}>bahannonews.com</h3>
                        <br />
                    </div>

                    <div className="footer-full-editor" style={{ float: 'left', marginBottom: 20 }}>
                        সম্পাদক ও প্রকাশক: বিভাষ বাড়ৈ
                    </div>
                    <br />
                </div>
            </div>
            <DetailsSection02 article={article} speArticleAr={relatedArticle} />
        </>
    );
}

export default DetailsSection01;
