import React, { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

function LoginFormBodyEmail({
    signIn,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    openRegistrationModal,
    openForgetPasswordModal,
}) {
    const refPhone = useRef();
    const refPassword = useRef();
    const { setMessage } = useTemplate();

    const handelPhoneLogin = (ev) => {
        ev.preventDefault();

        const email = refPhone.current.value;
        const password = refPassword.current.value;

        if (!email) {
            refPhone.current.focus();
            setMessage('Enter valid email number');
        } else if (!password) {
            refPassword.current.focus();
            setMessage('Enter password');
        } else {
            AxiosAuth.post(`${ConfigApi.API_LOGIN}login/email/data/`, {
                email,
                password,
            }).then(({ data }) => {
                setMessage(data.message);

                if (data.error === 0) {
                    // --Checking Local User
                    signIn(data.id, password);
                }
            });
        }
    };

    return (
        <>
            <Form>
                <Form.Group className="custom-login-input">
                    <Form.Label className="custom-label">Email</Form.Label>
                    <Form.Control
                        className="custom-input"
                        type="email"
                        placeholder="Enter Email Address"
                        ref={refPhone}
                    />
                </Form.Group>

                <Form.Group className="custom-login-input">
                    <Form.Label className="custom-label">Password</Form.Label>
                    <Form.Control
                        className="custom-input"
                        type="password"
                        placeholder="Password"
                        ref={refPassword}
                    />
                </Form.Group>

                <Form.Group className="custom-login-other">
                    <Form.Check
                        inline
                        label="Remember Me"
                        className="custom-login-other-label"
                        name="group1"
                        type="checkbox"
                    />
                    <a
                        href="."
                        onClick={(ev) => {
                            ev.preventDefault();
                            openForgetPasswordModal();
                        }}
                    >
                        Forgot Password ?
                    </a>
                </Form.Group>

                <Button
                    variant="primary"
                    type="submit"
                    className="custom-login-btn"
                    onClick={handelPhoneLogin}
                >
                    Login
                </Button>

                <Form.Group className="custom-login-other" style={{ textAlign: 'center' }}>
                    <p>or</p>
                </Form.Group>
            </Form>

            <Form.Group className="custom-login-social">
                <Button
                    type="button"
                    onClick={() => {
                        signInWithGoogle();
                    }}
                    className="custom-login-btn-google"
                >
                    Google
                </Button>
                <Button
                    type="button"
                    className="custom-login-btn-facebook"
                    onClick={signInWithFacebook}
                >
                    Facebook
                </Button>
                <Button
                    type="button"
                    className="custom-login-btn-twitter"
                    onClick={signInWithTwitter}
                >
                    Twitter
                </Button>
            </Form.Group>

            <Form.Group className="custom-login-footer">
                <p>
                    Don&apos;t have an account?{' '}
                    <span
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                            // setShowRegModal(true);
                            openRegistrationModal(false);
                        }}
                    >
                        Register Now
                    </span>
                </p>
            </Form.Group>
        </>
    );
}

export default LoginFormBodyEmail;
