/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    // eslint-disable-next-line prettier/prettier
    WhatsappShareButton
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import '../../assets/css/bottom-modal.css';
import copyIocn from '../../assets/images/copy-solid.svg';
import instraIcon from '../../assets/images/Instagram.svg';

function ShareDetailBox({ articleLink, show }) {
    const [tooltip, setTooltip] = useState('Copy Link');
    const handleCopy = () => {
        navigator.clipboard.writeText(articleLink);
        setTooltip('লিঙ্ক কপি হয়েছে');
        ReactTooltip.rebuild();
    };

    return (
        <div className="details-share-box-modal">
            <table className="details-share-box-table">
                <tbody>
                    <tr className="details-share-box-tr mb-4">
                        <td>
                            <FacebookShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <span>ফেসবুকে শেয়ার করুন</span>
                        </td>
                        <td>
                            <FacebookMessengerShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookMessengerIcon size={32} round />
                            </FacebookMessengerShareButton>
                            <span> ম্যাসেঞ্জারে শেয়ার করুন</span>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            <Button
                                data-tip
                                className="share-insta-btn"
                                data-for="copyTip"
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    padding: 0,
                                }}
                                onClick={handleCopy}
                                onMouseMove={() => setTooltip('লিঙ্ক কপি করুন')}
                            >
                                <img
                                    src={instraIcon}
                                    alt="Copy Icon"
                                    style={{ width: 20, height: 18 }}
                                />
                            </Button>
                            <span>ইন্সটাগ্রামে শেয়ার করুন</span>
                        </td>
                        <td>
                            <WhatsappShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <span>ওয়াটসঅ্যাপে শেয়ার করুন</span>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            {' '}
                            <TwitterShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <span>টুইটারে শেয়ার করুন</span>
                        </td>
                        <td>
                            <LinkedinShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <span>লিংকডইনে শেয়ার করুন</span>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            <EmailShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <span>ইমেইল করুন</span>
                        </td>
                        <td>
                            <Button
                                data-tip
                                data-for="copyTip"
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    padding: 0,
                                }}
                                onClick={handleCopy}
                                onMouseMove={() => setTooltip('লিঙ্ক কপি করুন')}
                            >
                                <img
                                    src={copyIocn}
                                    alt="Copy Icon"
                                    style={{ width: 20, height: 18 }}
                                />
                            </Button>
                            <ReactTooltip id="copyTip" place="top" effect="solid">
                                {tooltip}
                            </ReactTooltip>
                            <span> লিংক কপি করুন</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default function RecentNewsModal({ show, handleClose, latestArticles }) {
    const ref = useRef();
    const [moreShareShow, setMoreShareShow] = useState('none');

    const handleMoreShare = () => {
        setMoreShareShow('block');
    };

    const handleClickOutside = (ev) => {
        if (!(ref && ref.current?.contains(ev.target))) {
            // alert('You clicked outside of me!');
            setMoreShareShow('none');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);

    if (!latestArticles) {
        return null;
    }

    return (
        <>
            <Modal
                show={show}
                className=" modal modal-bottom fade"
                data-backdrop="static"
                style={{ width: 300, zIndex: 99999 }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content bottom-modal-content">
                        <div className="modal-body">
                            <div className="recent-news-title">
                                <button
                                    type="button"
                                    className="custom-close-recent close ml-1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => handleClose(latestArticles.sl)}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                {/* <Button
                                        className="share-print-btn float-right"
                                        style={{
                                            width: 32,
                                            height: 32,
                                            borderRadius: '50%',
                                            padding: 0,
                                            paddingLeft: '2px',
                                        }}
                                        onClick={handleMoreShare}
                                    >
                                        <img
                                            src={moreIcon}
                                            alt="Print Icon"
                                            style={{ width: 18, height: 28 }}
                                        />
                                    </Button> */}
                            </div>
                            <div className="container">
                                <div className="recent-news-btn">
                                    <Link to="/news/all/">সদ্য পাওয়া খবর</Link>
                                </div>
                                <Link
                                    to={latestArticles.link}
                                    className="recent-news"
                                    onClick={() => handleClose(latestArticles.sl)}
                                >
                                    {latestArticles.title}
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <ShareDetailBox show={moreShareShow} ref={ref} /> */}
                </div>
            </Modal>
        </>
    );
}
