import crypto from 'crypto';
import { getToken } from 'firebase/messaging';
import { messaging } from './projects/MyFirebase';
import AxiosAuth from './projects/utils/AxiosAuth';

const vapidKey = process.env.REACT_APP_VAPID_KEY;

// const firebaseApp = initializeApp(firebaseConfig);
// let messaging = null;
// if (navigator?.serviceWorker && window.Notification?.permission) {
//     messaging = getMessaging(firebaseApp);
// }

function saveFcmOnDB(currentToken) {
    const domainId = '10000001';
    const domainSecret = '202cb962ac59075b964b07152d234b70';
    const hash = crypto
        .createHash('md5')
        .update(`${domainId}/${domainSecret}/${currentToken}`)
        .digest('hex');

    AxiosAuth.get(`https://push.bikiran.com/api/save-fcm/${domainId}/${hash}/${currentToken}/`);
}

export const getTokenId = async (setTokenFound) => {
    let currentToken = '';

    if (messaging !== null) {
        try {
            currentToken = await getToken(messaging, { vapidKey });
            if (currentToken) {
                setTokenFound(true);
            } else {
                setTokenFound(false);
            }

            saveFcmOnDB(currentToken);
        } catch (error) {
            console.log('An error occurred while retrieving token. ', error);
        }
    }

    return currentToken;
};

export const onMessageListener = () =>
    new Promise((resolve, reject) => {
        if (messaging !== null) {
            messaging.onMessage((payload) => {
                resolve(payload);
            });
        } else {
            reject(new Error('Messaging not Allowed'));
        }
    });
