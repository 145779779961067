import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PageProperties from '../../../models/PageProperties';
import { setActiveMenu } from '../../../utils/Menu';

export default function TermsPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenu('/'));
    }, [dispatch]);

    PageProperties('নীতিমালা');

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mt-3 mb-3">
                    <div className="home-category-news-title" style={{ width: '100%' }}>
                        <div className="home-category-news-title-sub" style={{ width: 150 }}>
                            <Link to="privacy">নীতিমালা</Link>
                        </div>
                    </div>
                    <div className="details-news-body" style={{ width: '100%' }}>
                        <div className="details-news-body-detail" style={{ minHeight: 500 }}>
                            <h4>LOG FILES</h4>
                            <p>
                                Like many other Web sites,
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                makes use of log files. The information inside the log files
                                includes internet protocol (IP)addresses, type of browser, Internet
                                Service Provider ( ISP ), date/time stamp, referring/exit pages, and
                                number of clicks to analyze trends, administer the site, track
                                user’s movement around the site, and gather demographic information.
                                IP addresses, and other such information are not linked to any
                                information that is personally identifiable.
                            </p>
                            <br />
                            <h4>COOKIES AND WEB BEACONS </h4>
                            <p>
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>{' '}
                                does use cookies to store information about visitors preferences,
                                record user-specific information on which pages the user access or
                                visit, customize Web page content based on visitors browser type or
                                other information that the visitor sends via their browser.
                            </p>
                            <br />
                            <h4>DOUBLECLICK DART COOKIE </h4>
                            <p>
                                1. Google, as a third party vendor, uses cookies to serve ads on
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                . <br />
                                2. Google&#39;s use of the DART cookie enables it to serve ads to
                                users based on their visit to
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                and other sites on the Internet.
                            </p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
