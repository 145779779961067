import React from 'react';
import { Link } from 'react-router-dom';
import {
    Ads01,
    Ads05,
    Ads06,
    Ads08,
    Ads09,
    Ads14Full,
    Ads19,
    // eslint-disable-next-line prettier/prettier
    Ads20
} from '../../../components/Ads';
import FbPagePreview from '../../../components/FbPagePreview';
import LatestAndTopNews from '../../../components/LatestAndTopNews';
import PlayButton from '../../../components/PlayButton';
import Iopt from '../../../utils/Iopt';
import HeroAndUpdatedNews from '../components/HeroAndUpdatedNews';
import SideBarNewsWithLeadExclusive from '../components/SideBarNewsWithLeadExclusive';
import SidebarOpinion from '../components/SidebarOpinion';
import SpecialBoxNews from '../components/SpecialBoxNews';

const HeroNewsSort = ({ article }) => {
    // const cat = PickCategoryByPageId(article.category, article.page);

    if (!article) {
        return null;
    }

    return (
        <Link to={article.link} className="hero-news-short link-area">
            <div className="hero-news-short-img image-area">
                <img src={Iopt(article.image, 320)} alt={article.title} />
                <PlayButton show={article.having_video} />
            </div>
            <div className="hero-news-short-title link-title">{article.title}</div>
        </Link>
    );
};

const HeroNewsSortRow = ({ article1, article2, article3 }) => (
    <div className="row">
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article1} />
        </div>
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article2} />
        </div>
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article3} />
        </div>
    </div>
);

function Left({ leadArticles, latestArticles, popularArticles }) {
    return (
        <>
            <HeroAndUpdatedNews
                heroNewsArticle={leadArticles[0]}
                leadArticles={leadArticles}
                latestArticles={latestArticles}
                popularArticles={popularArticles}
            />

            <div className="mb-3">
                <Ads05 show />
            </div>

            <HeroNewsSortRow
                article1={leadArticles[1]}
                article2={leadArticles[2]}
                article3={leadArticles[3]}
            />

            <div className="mb-3">
                <Ads08 show />
            </div>

            <HeroNewsSortRow
                article1={leadArticles[4]}
                article2={leadArticles[5]}
                article3={leadArticles[6]}
            />

            <div className="mb-3">
                <Ads09 show />
            </div>

            <HeroNewsSortRow
                article1={leadArticles[7]}
                article2={leadArticles[8]}
                article3={leadArticles[9]}
            />

            <div className="mb-3">
                <Ads06 show />
            </div>

            <div className="d-block d-md-none">
                <LatestAndTopNews
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                    maxArticle={5}
                />
            </div>
        </>
    );
}

function RightSidebar({
    onlineOpinionArticleAr,
    speArticleAr,
    speArticleArLead,
    eduArticleAr,
    // eduArticleArLead,
}) {
    return (
        <>
            <div className="d-block d-md-none">
                <Ads14Full show mode="placeholder" />
            </div>

            <SidebarOpinion
                title="মতামত"
                col="sidebar-category-news-title"
                articleAr={onlineOpinionArticleAr}
                maxArticle={2}
            />
            <Ads19 show />

            <SideBarNewsWithLeadExclusive
                title="বাহান্ন স্পটলাইট"
                articleAr={speArticleAr}
                articleArLead={speArticleArLead}
                topNews
                hideMore
            />

            {/* <div className="sidebar-converter">
                <a href="https://converter.bahannonews.com/" target="_blank" rel="noreferrer">
                    <img src={ConverterImg} alt="Converter" />
                </a>
            </div> */}

            {/* <Ads12 show /> */}

            <FbPagePreview />
            {/* <SideBarNewsWithLeadEducation
                title="শিক্ষা"
                articleAr={eduArticleAr}
                articleArLead={eduArticleArLead}
            /> */}
            <Ads20 show />
            <div className="col-12">
                <div className="special-feature">
                    <div className="sidebar-category-news-title-edu">
                        <div className="sidebar-category-news-title-edu-sub">
                            <Link to="/online/education/">শিক্ষা</Link>
                        </div>
                    </div>
                    <div className="row special-feature-padding">
                        {Object.values(eduArticleAr)
                            .sort((item1, item2) => {
                                if (item1.time_published > item2.time_published) {
                                    return -1;
                                }
                                if (item1.time_published < item2.time_published) {
                                    return 1;
                                }

                                return 0;
                            })
                            .slice(0, 4)
                            .map((row) => (
                                <div className="col-12 col-xl-3 col-lg-3">
                                    <SpecialBoxNews article={row} key={row.sl} />
                                </div>
                            ))}
                        <div className="col-12 special-feature-footer">
                            <Link to="online/education">আরও...</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <Ads14Full show mode="placeholder" />
            </div>
            {/* <div style={{ marginBottom: '30px', display: 'none' }} ref={ref}>
                {fbPageWidth > 200 ? (
                    <FacebookProvider appId={configs.fbAppId}>
                        <Page href={configs.facebook} width={fbPageWidth} />
                    </FacebookProvider>
                ) : null}
            </div> */}
        </>
    );
}

function HomeSection01Main({
    leadArticles,
    speArticleAr,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles,
}) {
    const speArticleArLead = speArticleAr[0];
    const eduArticleArLead = eduArticleAr[0];

    return (
        <div className="container">
            <Ads01 />

            <Left
                leadArticles={leadArticles}
                latestArticles={latestArticles}
                popularArticles={popularArticles}
            />

            <RightSidebar
                onlineOpinionArticleAr={onlineOpinionArticleAr}
                speArticleAr={speArticleAr}
                speArticleArLead={speArticleArLead}
                eduArticleAr={eduArticleAr}
                eduArticleArLead={eduArticleArLead}
            />
        </div>
    );
}

function HomeSection01Xs({
    leadArticles,
    speArticleAr,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles,
}) {
    return (
        <HomeSection01Main
            leadArticles={leadArticles}
            speArticleAr={speArticleAr}
            popularArticles={popularArticles}
            eduArticleAr={eduArticleAr}
            onlineOpinionArticleAr={onlineOpinionArticleAr}
            latestArticles={latestArticles}
        />
    );
}

export default HomeSection01Xs;
