import React from 'react';
import { Link } from 'react-router-dom';
import notFoundImage from '../../../../assets/images/404.svg';

function NotFoundSection() {
    return (
        <div className="container">
            <div className="not-found-404">
                <img src={notFoundImage} alt="404" />
                <h2>নিউজ টি পাওয়া যায়নি</h2>
                {/* <div className="not-found-404-text">আপনি যা খুঁজছেন, তা পাওয়া যায়নি।</div> */}
                {/* <div className="not-found-404-search">
                    <div className="header-search-bar">
                        <div className="header-search-input">
                            <div className="header-search-group">
                                <div className="header-search-text-input">
                                    <input placeholder="এখানে লিখুন..." />
                                </div>
                                <div className="header-search-send">
                                    <img src={SearchIcon} alt="Send Icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="not-found-404-btn">
                    <Link to="/">প্রচ্ছদে ফিরে যান</Link>
                </div>
            </div>
        </div>
    );
}

export default NotFoundSection;
