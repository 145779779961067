import React from 'react';
import { Ads05, Ads06, Ads19, Ads20 } from '../../../components/Ads';
import LatestAndTopNews from '../../../components/LatestAndTopNews';
import ThreeTabNews from '../../../components/ThreeTabNews';
import BrowserInfo from '../../../utils/BrowserInfo';
import SidebarCalendar from '../components/SidebarCalendar';
import DefaultSidebarSectionMd from './DefaultSidebarSectionMd';

function DefaultSidebarSectionMain({
    latestArticles,
    articles1,
    title1,
    articles2,
    title2,
    articles3,
    title3,
}) {
    return (
        <div className="col col-300-1 not-print-class">
            <Ads05 show />
            <Ads19 show mode="placeholder" />
            <ThreeTabNews
                articles1={articles1}
                title1={title1}
                articles2={articles2}
                title2={title2}
                articles3={articles3}
                title3={title3}
            />
            <Ads06 show name="gpt-passback6" />
            <LatestAndTopNews latestArticles={latestArticles} maxArticle={5} />
            <div className="col-12 d-md-none d-lg-block d-xl-block">
                <Ads20 show />
            </div>
            <SidebarCalendar />
        </div>
    );
}

function DefaultSidebarSection({
    latestArticles,
    articles1,
    title1,
    articles2,
    title2,
    articles3,
    title3,
}) {
    const { sizeName } = BrowserInfo();

    if (sizeName === 'bs-md') {
        return (
            <DefaultSidebarSectionMd
                latestArticles={latestArticles}
                articles1={articles1}
                title1={title1}
                articles2={articles2}
                title2={title2}
                articles3={articles3}
                title3={title3}
            />
        );
    }

    return (
        <DefaultSidebarSectionMain
            latestArticles={latestArticles}
            articles1={articles1}
            title1={title1}
            articles2={articles2}
            title2={title2}
            articles3={articles3}
            title3={title3}
        />
    );
}

export default DefaultSidebarSection;
