import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/PlayButton';

export default function SideBarNewsWithLeadExclusive({ title, articleArLead, hideMore = false }) {
    if (!articleArLead) {
        return null;
    }

    return (
        <div className="sidebar-category-news">
            <div className="sidebar-category-news-title-ex">
                <div className="sidebar-category-news-title-ex-sub">
                    <Link to={`/${articleArLead?.category}/${articleArLead?.page}`}>{title}</Link>
                </div>
            </div>
            <Link to={articleArLead?.link} className="side-news-short link-area-ex link-area">
                <div className="side-news-short-img image-area">
                    <img src={articleArLead?.image} alt="news" />
                    <PlayButton show={articleArLead?.having_video} />
                </div>
                <div className="side-news-short-title link-title-ex" style={{ height: 40 }}>
                    {articleArLead.title}
                </div>
            </Link>

            {hideMore ? null : (
                <div className="category-news-footer">
                    <div className="link-area">
                        <Link
                            to={`/${articleArLead.category}/${articleArLead.page}/`}
                            className="category-news-footer-text link-title"
                        >
                            আরও...
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}
