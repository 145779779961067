import React from 'react';
import { Link } from 'react-router-dom';
import Iopt from '../utils/Iopt';
import PlayButton from './PlayButton';

export default function UpdatedNewsListStyle2({ articleAr, pageId, maxArticle = 10 }) {
    return (
        <div className="updated-news-style-2">
            {Object.values(articleAr)
                .sort((item1, item2) => {
                    if (item1.time_published > item2.time_published) {
                        return -1;
                    }
                    if (item1.time_published < item2.time_published) {
                        return 1;
                    }

                    return 0;
                })
                .slice(1, maxArticle)
                .map((row) => (
                    <Link to={row.link} className="article-list-item link-area" key={row.sl}>
                        <div className={['row h-100 gx-2 online', pageId].join(' ')}>
                            <div className="col-4 align-self-center">
                                <div className="image-area">
                                    <img src={Iopt(row.image, 120)} alt="im" />
                                    <PlayButton show={row.having_video} />
                                </div>
                            </div>
                            <div className="col-8 align-self-center">
                                <div className="text-area">{row.title}</div>
                            </div>
                        </div>
                    </Link>
                ))}
            <div className="category-news-footer">
                <div className="link-area">
                    <a className="category-news-footer-text link-title" href={`/online/${pageId}/`}>
                        আরও...
                    </a>
                </div>
            </div>
        </div>
    );
}
