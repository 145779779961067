import React from 'react';
import { Link } from 'react-router-dom';
import Iopt from '../../../utils/Iopt';

export default function BoxNews({ article }) {
    if (!article) {
        return null;
    }
    return (
        <Link to={article.link} className="category-hero-news-short link-area">
            <div className="hero-news-short-img">
                <img src={Iopt(article.image, 360)} alt="news" />
            </div>
            <div className="hero-news-short-title link-title">{article.title}</div>
        </Link>
    );
}
