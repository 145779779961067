import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'react-bootstrap-carousel/dist/react-bootstrap-carousel.css';
import { Provider } from 'react-redux';
import '../assets/css/style.css';
import '../assets/css/style.responsive.css';
import '../assets/css/template.css';
import '../assets/css/top-box.css';
import '../assets/css/top-option.css';
import '../assets/css/user.css';
import AuthProvider from './contexts/AuthProvider';
import TemplateProvider from './contexts/TemplateProvider';
import RouteHandler from './RouteHandler';
import store from './store';
import NotificationWindow from './utils/NotificationWindow';
import ScrollToTop from './utils/ScrollToTop';

function App() {
    return (
        <TemplateProvider>
            <AuthProvider>
                <Provider store={store}>
                    <RouteHandler />
                    {/* <Notifications /> */}
                    <NotificationWindow />
                    <ScrollToTop />
                </Provider>
            </AuthProvider>
        </TemplateProvider>
    );
}

export default App;
