/* eslint-disable no-unused-vars */
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';
import { EmailIcon, EmailShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import CircleIcon from '../../../../assets/images/circle-solid.svg';
import FooterEditPen from '../../../../assets/images/footer-edit-pen.svg';
import YtIcon from '../../../../assets/images/yt-white.svg';
import InitInfo from '../../../utils/InitInfo';

const SocialMediaLink = ({ fb, tt, yt }) => (
    <ul>
        <li>
            <a href={fb} target="_blank" rel="noreferrer">
                <FacebookIcon size={32} round="true" />
            </a>
        </li>
        <li>
            <a href={tt} target="_blank" rel="noreferrer">
                <TwitterIcon size={32} round="true" />
            </a>
        </li>
        <li>
            <a href={yt} target="_blank" rel="noreferrer">
                <img src={YtIcon} width={32} round="true" alt="Yt Icon" />
            </a>
        </li>
        <li>
            <EmailShareButton
                url="https://bahannonews.com/"
                className="Demo__some-network__share-button"
            >
                <EmailIcon size={32} round="true" />
            </EmailShareButton>
        </li>
    </ul>
);

const MarqueeNews = ({ articles }) => (
    <Link to={articles.link}>
        <img src={CircleIcon} alt="Circle" style={{ width: 7, marginLeft: 10, marginBottom: 4 }} />{' '}
        {articles.title}
    </Link>
);

const TvStyle = ({ latestArticles = [] }) => (
    <div className="tv-style">
        <div className="title">শিরোনাম:</div>
        <div className="text">
            <Marquee pauseOnHover speed={40}>
                {Object.values(latestArticles)
                    .sort((item1, item2) => {
                        if (item1.time_published > item2.time_published) {
                            return -1;
                        }
                        if (item1.time_published < item2.time_published) {
                            return 1;
                        }

                        return 0;
                    })
                    .map((rows) => (
                        <MarqueeNews articles={rows} key={rows.sl} />
                    ))}
            </Marquee>
        </div>
    </div>
);

const BreakingNews = ({ latestArticles }) => (
    <div className="tv-style">
        <div className="title">ব্রেকিং:</div>
        <div className="text" style={{ textAlign: 'center' }}>
            <div className="lineUp">
                <Link to={latestArticles.link}>{latestArticles.title}</Link>
            </div>
        </div>
    </div>
);

function FooterCategories({ categoryAr }) {
    return (
        <div className="col-12 col-lg-6 col-xl-6 footer-border-image">
            <div className="footer-full-menu">
                <ul>
                    <li>
                        <a href="/">প্রচ্ছদ</a>
                    </li>
                    {categoryAr.online.slice(0, 13).map((rows) => (
                        <li key={rows.sl}>
                            <Link to={rows.url}>{rows.title} </Link>
                        </li>
                    ))}
                    <li>
                        <Link to="/news/all/">সব খবর</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

const FooterImportantLinks = () => {
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    const tDay = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
        date < 10 ? `0${date}` : `${date}`
    }`;

    return (
        <div className="col-12 col-lg-3 col-xl-3 footer-border-image">
            <div className="footer-full-menu">
                <ul style={{ marginBottom: '0px' }}>
                    <li>
                        <Link to="/contact/">যোগাযোগ</Link>
                    </li>
                    <li>
                        <Link to="/terms-condition/">শর্তাবলী</Link>
                    </li>
                    <li>
                        <Link to={`/archive/${tDay}/`}>আর্কাইভ</Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link to="/privacy-policy/">গোপনীয়তা নীতি</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

function FooterSocialLinks({ configs }) {
    return (
        <div className="col-12 col-lg-3 col-xl-3">
            <div className="footer-full-share">
                <h5>আমাদের অনুসরণ করুন</h5>
                <SocialMediaLink fb={configs.facebook} tt={configs.twitter} yt={configs.youtube} />
            </div>
        </div>
    );
}

function FooterSection1({ categoryAr, configs }) {
    return (
        <div className="footer-full-fst">
            <div className="container">
                <div className="row g-0" style={{ paddingTop: '10px' }}>
                    <FooterCategories categoryAr={categoryAr} />

                    <FooterImportantLinks />

                    <FooterSocialLinks configs={configs} />
                </div>
            </div>
        </div>
    );
}

function FooterSection2() {
    return (
        <div className="footer-full-2nd">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 col-xl-4">
                        <div className="footer-full-editor">
                            <img src={FooterEditPen} alt="edit" />
                            সম্পাদক ও প্রকাশক: বিভাষ বাড়ৈ
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4" />
                    <div className="col-12 col-lg-4 col-xl-4">
                        {/* <div className="footer-full-app-download">
                <div className="footer-full-app-download-btn">
                    <button type="button">
                        <img src={PlayStoreIcon} alt="PlayStore" /> ডাউনলোড করুন
                    </button>
                </div>
            </div> */}
                        <div className="footer-full-develop d-none d-lg-block">
                            Developed By:<a href="https://bikiran.com"> Bikiran.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FooterSection3() {
    return (
        <div className="footer-full-3rd">
            <div className="container">
                <div className="row">
                    <div className="col-12 ">
                        <div className="footer-full-all-right">
                            2021, © All rights reserved{' '}
                            <a style={{ color: 'white' }} href="https://bahannonews.com">
                                {' '}
                                www.bahannonews.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FooterSectionSecond() {
    const { configs, categoryAr, latestArticles } = InitInfo();
    // const arr = initData.footCatPageIds;

    if (!categoryAr?.online) {
        return null;
    }
    return (
        <>
            <div className="footer-full not-print-class">
                <FooterSection1 configs={configs} categoryAr={categoryAr} />

                <FooterSection2 />

                <FooterSection3 />

                <div className="floating-button">
                    <Link to="/online/jobs/">চাকরির খবর</Link>
                </div>

                <TvStyle latestArticles={latestArticles} />
                {/* <BreakingNews latestArticles={latestArticles[0]} /> */}
            </div>

            {/* <RecentNewsModal show={show} handleClose={handleClose} /> */}
            {/* <FooterFloatingAd /> */}
        </>
    );
}

export default FooterSectionSecond;
