/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EmailIcon, EmailShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import Logo from '../../../../assets/images/bahanno-logo.svg';
import searchBar from '../../../../assets/images/menu-search-icon-.svg';
import MoreIcon from '../../../../assets/images/more-icon-bar.svg';
import NotificationIcon from '../../../../assets/images/Notification-icon.svg';
import SearchIcon from '../../../../assets/images/Search.svg';
import UserIcon from '../../../../assets/images/user-icon.svg';
import YtIcon from '../../../../assets/images/yt.svg';
import SidebarMenu from '../../../components/SidebarMenu';
import VideoPlayModal from '../../../components/VideoPlayModal';
import { useAuth } from '../../../contexts/AuthProvider';
import ConvertStringBn from '../../../utils/ConvertStringBn';
import InitInfo from '../../../utils/InitInfo';
import Iopt from '../../../utils/Iopt';
import OptionBox from '../../../utils/OptionBox';
import UserInfo from '../components/UserInfo';
import ReactGaSection from './ReactGaSection';

const SocialMediaLink = ({ fb, tt, yt }) => (
    <div className="header-top-social-list d-none d-lg-block d-xl-block">
        <ul>
            <li>
                <a href={fb} target="_blank" rel="noreferrer">
                    <FacebookIcon size={32} round="true" />
                </a>
            </li>
            <li>
                <a href={tt} target="_blank" rel="noreferrer">
                    <TwitterIcon size={32} round="true" />
                </a>
            </li>
            <li>
                <a href={yt} target="_blank" rel="noreferrer">
                    <img src={YtIcon} width={32} round="true" alt="Yt Icon" />
                </a>
            </li>
            <li>
                <EmailShareButton
                    target="_blank"
                    url="https://bahannonews.com/"
                    className="Demo__some-network__share-button"
                >
                    <EmailIcon size={32} round="true" />
                </EmailShareButton>
            </li>
        </ul>
    </div>
);
const TimeShow = ({ time }) => {
    const days = {
        Sun: 'Sunday',
        Mon: 'Monday',
        Tue: 'Tuesday',
        Wed: 'Wednesday',
        Thu: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday',
    };
    const newDate = new Date(time * 1000);
    const [day, month, date, year] = newDate.toDateString().split(' ');
    // const minutes = newDate.getMinutes().toString().padStart(2, '0');

    let hours = newDate.getHours();
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;
    hours = hours.toString().padStart(2, '0');
    // const amPm = newDate.toLocaleTimeString('en-BD').split(' ')[1];

    if (!time) {
        return null;
    }

    return (
        <span>{ConvertStringBn(`${days[day]}, ${date} ${month} ${year}`)}</span>
        // <p>{ConvertStringBn(`${day}, ${date} ${month} ${year}, ${hours}:${minutes} ${amPm}`)}</p>
    );
};

const SearchArea = ({ show }) => {
    const inp = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const q = new URLSearchParams(location.search).get('q');
    const [searchKey, setSearchKey] = useState(q);

    const onSearchClick = (ev, mode) => {
        const moveToSearch = () => {
            navigate(`/search/?q=${encodeURIComponent(searchKey)}`);
        };

        if (mode === 'change') {
            setSearchKey(ev.target.value);
        }

        if (mode === 'keyup') {
            if (ev.keyCode === 13) {
                // On press enter
                moveToSearch();
            } else {
                setSearchKey(ev.target.value);
            }
        } else if (mode === 'searchClick') {
            moveToSearch();
        }
    };

    useEffect(() => {
        inp.current.focus();
    }, []);

    return (
        <div
            className={`header-search-group ${
                show ? 'header-search-bar2-show' : 'header-search-bar2-hide'
            }`}
        >
            <div className="header-search-text-input">
                <input
                    ref={inp}
                    placeholder="এখানে লিখুন..."
                    onKeyUp={(ev) => {
                        onSearchClick(ev, 'keyup');
                    }}
                    value={searchKey || ''}
                    onChange={(ev) => {
                        onSearchClick(ev, 'change');
                    }}
                />
            </div>
            <button
                type="button"
                className="header-search-send"
                onClick={(ev) => {
                    onSearchClick(ev, 'searchClick');
                }}
            >
                <img src={SearchIcon} alt="Send Icon" />
            </button>
        </div>
    );
};

const SearchOption = () => {
    const ref = useRef();
    const [searchShow, setSearchShow] = useState(false);

    const handleClickOutside = (ev) => {
        if (!(ref && ref.current?.contains(ev.target))) {
            // alert('You clicked outside of me!');
            setSearchShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (searchShow) {
        return (
            <div className="header-search-bar2" ref={ref}>
                <div className="header-search-input">
                    <SearchArea show={searchShow} setSearchShow={setSearchShow} />
                </div>
            </div>
        );
    }

    return (
        <button
            type="button"
            onClick={() => {
                setSearchShow(true);
            }}
            className="search-icon"
        >
            <img
                src={searchBar}
                alt="Search Bar"
                className={`${!searchShow ? 'header-search-icon-show' : 'header-search-bar2-hide'}`}
            />
        </button>
    );
};

export default function HeaderSectionSecond() {
    const { initData, configs } = InitInfo();

    const { openLoginModal, currentUser } = useAuth();
    const [show, setShow] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const [showUBox, setShowUBox] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="container">
                {/* <div
                    className="menu-top-publicity"
                    style={{ display: localStorage.getItem('showTopAd') }}
                >
                    <img src={TopAdd} alt="Publicity" />
                    <div className="menu-top-cross">
                        <button type="button" onClick={handleshowTopAd}>
                            Close Ad
                        </button>
                    </div>
                </div> */}

                <ReactGaSection />

                <div className="header-border">
                    <div className="header-top row">
                        <div className="col col-2 col-md-1 col-lg-4 col-xl-4 align-self-center not-print-class">
                            <div className="header-more-icon d-lg-none d-xl-none">
                                <img src={MoreIcon} alt="More Icon" onClick={handleShow} />
                            </div>
                            <div className="header-top-bar-icon d-none d-lg-block d-xl-block">
                                <p>
                                    {/* <img
                                        src={EkushIcon}
                                        alt="Victory"
                                        style={{ height: 75, marginTop: -9, cursor: 'pointer' }}
                                        onClick={() => {
                                            setShowVideo(true);
                                        }}
                                    /> */}
                                    সংস্করণ:<b style={{ color: 'black' }}> বাংলা</b>{' '}
                                    <SearchOption />
                                </p>
                            </div>
                            <div className="header-top-bar-date-time d-none d-lg-block d-xl-block">
                                {initData.currentTime ? (
                                    <TimeShow time={initData.currentTime} />
                                ) : (
                                    <span
                                        style={{
                                            width: 296,
                                            height: 23,
                                            backgroundColor: '#E8F2FA',
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col col-5 col-md-9 col-lg-4 col-xl-4 header-logo-align">
                            <a href="/" className="header-top-logo2 ">
                                <img src={Logo} alt="Logo" />
                            </a>
                        </div>
                        <div className="col col-5 col-md-2 col-lg-4 col-xl-4 align-self-center not-print-class">
                            <div className="header-user ">
                                {/* <a href="/" onClick={handleLogin} target="_blank" rel="noreferrer">
                                    <img src={MujibYear} alt="Mujib" className="mujib-year" />
                                </a> */}

                                {currentUser?.userFound ? (
                                    <div
                                        role="button"
                                        tabIndex={-1}
                                        className={['top-icon', showUBox ? 'box-showing' : ''].join(
                                            ' '
                                        )}
                                        onClick={() => {
                                            setShowUBox(true);
                                        }}
                                    >
                                        <img
                                            src={Iopt(currentUser.photoUrl)}
                                            className="header-user-img"
                                            alt="User Icon"
                                        />
                                    </div>
                                ) : (
                                    <img
                                        src={UserIcon}
                                        className="header-user-img"
                                        alt="User Icon"
                                        onClick={() => {
                                            openLoginModal();
                                        }}
                                    />
                                )}

                                <img
                                    src={NotificationIcon}
                                    alt="User Icon"
                                    className="header-usern-notification"
                                />
                                <OptionBox show={showUBox} setShowUBox={setShowUBox}>
                                    <UserInfo userInformation={currentUser} />
                                </OptionBox>
                            </div>
                            <div className="header-top-social d-none d-lg-block d-xl-block">
                                <SocialMediaLink
                                    fb={configs.facebook}
                                    tt={configs.twitter}
                                    li={configs.linkedin}
                                    yt={configs.youtube}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <SidebarMenu show={show} handleClose={handleClose} />
                <VideoPlayModal show={showVideo} setShowVideo={setShowVideo} />
            </div>
        </>
    );
}
