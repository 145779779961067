import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import PageProperties from '../../../models/PageProperties';
import ArchivePagePlaceholder from '../../../placeholders/ArchivePagePlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import { setActiveMenu } from '../../../utils/Menu';
import ArchiveSection from '../section/ArchiveSection';
import DefaultSidebarSection from '../section/DefaultSidebarSection';

function ArchivePageControl() {
    const dispatch = useDispatch();
    const { date } = useParams();
    const { categoryAr } = InitInfo();
    const page = `archive-${date}`;
    const [articleData, setArticleData] = useState(null);

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                [page]: 100,
            },
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [page]);

    useEffect(() => {
        dispatch(setActiveMenu('/'));
    }, [dispatch]);

    PageProperties(`আর্কাইভ ${date}`);

    if (articleData === null) {
        return <ArchivePagePlaceholder />;
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <ArchiveSection articles={articleData[page]} categoryAr={categoryAr} date={date} />

                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function ArchivePage() {
    const { date } = useParams();
    return <ArchivePageControl key={date} />;
}
