import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import PageProperties from '../../../models/PageProperties';
import DetailsPlaceholder from '../../../placeholders/DetailsPlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/Menu';
import DefaultSidebarSection from '../section/DefaultSidebarSection';
import FlowDetailSection from '../section/FlowDetailSection';
import FlowSidebarSection from '../section/FlowSidebarSection';
import NotFoundSection from '../section/NotFoundSection';

function DetailPageControl() {
    const dispatch = useDispatch();

    const { articleId } = useParams();
    const [articleData, setArticleData] = useState(null);

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                articleDetails: articleId,
            },
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [articleId]);

    if (articleData === null) {
        return <DetailsPlaceholder />;
    }

    const article = articleData.articleDetails;
    PageProperties(article?.title || '');

    const pageId = article?.page || 'na';
    if (pageId) {
        dispatch(setActiveMenu(pageId));
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col col-840">
                    <div className="row">
                        <FlowSidebarSection latestArticles={articleData.recent} />
                        <div className="col col-840">
                            {articleData.recent ? (
                                <>
                                    <FlowDetailSection article={article} />
                                </>
                            ) : (
                                <NotFoundSection />
                            )}
                        </div>
                    </div>
                </div>
                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function DetailPageFlow() {
    const { articleId } = useParams();
    return <DetailPageControl key={articleId} />;
}
