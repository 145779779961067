import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import PageProperties from '../../../models/PageProperties';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/Menu';
import DefaultSidebarSection from '../section/DefaultSidebarSection';
import SearchSection from '../section/SearchSection';

function SearchPageControl() {
    const dispatch = useDispatch();
    const location = useLocation();
    const pageId = '/';

    const q = new URLSearchParams(location.search).get('q');
    const page = `search-${q}`;
    const [articleData, setArticleData] = useState(null);

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                [page]: 30,
            },
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [page]);

    useEffect(() => {
        dispatch(setActiveMenu(pageId));
    }, [dispatch]);

    PageProperties(`অনুসন্ধান: ${q}`);

    if (articleData === null) {
        return null;
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <SearchSection articles={articleData[page] || []} q={q} />

                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function SearchPage() {
    const { q } = useParams();

    return <SearchPageControl key={q} />;
}
