import React, { useEffect, useRef, useState } from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import { Link } from 'react-router-dom';
import { Ads05, Ads08, Ads09, Ads12 } from '../../../components/Ads';
import LatestAndTopNews from '../../../components/LatestAndTopNews';
import PlayButton from '../../../components/PlayButton';
import InitInfo from '../../../utils/InitInfo';
import Iopt from '../../../utils/Iopt';
import HeroAndUpdatedNewsLg from '../components/HeroAndUpdatedNewsLg';
import SideBarNewsWithLeadEducation from '../components/SideBarNewsWithLeadEducation';
import SideBarNewsWithLeadExclusive from '../components/SideBarNewsWithLeadExclusive';
import SidebarOpinion from '../components/SidebarOpinion';

const HeroNewsSort = ({ article }) => {
    // const cat = PickCategoryByPageId(article.category, article.page);

    if (!article) {
        return null;
    }

    return (
        <Link to={article.link} className="hero-news-short link-area">
            <div className="hero-news-short-img image-area">
                <img src={Iopt(article.image, 320)} alt={article.title} />
                <PlayButton show={article.having_video} />
            </div>
            <div className="hero-news-short-title link-title">{article.title}</div>
            {/* <div className="hero-news-short-footer">
                <Link to={`/${article.category}/${article.page}/`}>{cat.title}</Link>
            </div> */}
        </Link>
    );
};

const HeroNewsSortRow = ({ article1, article2, article3 }) => (
    <div className="row">
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article1} />
        </div>
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article2} />
        </div>
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article3} />
        </div>
    </div>
);

function FbPagePreview() {
    const { configs } = InitInfo();
    const [fbPageWidth, setFbPageWidth] = useState(0);

    const ref = useRef();
    useEffect(() => {
        // ref
        // console.dir(ref.current.clientWidth);
        setFbPageWidth(ref?.current.clientWidth);
    }, []);

    return (
        <div style={{ marginBottom: '30px' }} ref={ref}>
            {fbPageWidth > 200 ? (
                <FacebookProvider appId={configs.fbAppId}>
                    <Page href={configs.facebook} width={fbPageWidth} />
                </FacebookProvider>
            ) : null}
        </div>
    );
}

function Left({ leadArticles, latestArticles, popularArticles }) {
    return (
        <div className="col col-840">
            <div>
                <HeroAndUpdatedNewsLg
                    heroNewsArticle={leadArticles[0]}
                    leadArticles={leadArticles}
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                    ads={
                        <div>
                            <div className="mb-4">
                                <Ads08 show />
                            </div>
                            <div className="mb-4">
                                <Ads09 show />
                            </div>
                        </div>
                    }
                />

                <HeroNewsSortRow
                    article1={leadArticles[1]}
                    article2={leadArticles[2]}
                    article3={leadArticles[3]}
                />

                <HeroNewsSortRow
                    article1={leadArticles[4]}
                    article2={leadArticles[5]}
                    article3={leadArticles[6]}
                />

                <div className="row">
                    <div className="col d-none  d-lg-block mb-4">
                        <Ads08 show />
                    </div>
                    <div className="col d-none  d-lg-block">
                        <Ads09 show />
                    </div>
                </div>

                <HeroNewsSortRow
                    article1={leadArticles[7]}
                    article2={leadArticles[8]}
                    article3={leadArticles[9]}
                />

                <div className="d-block d-md-none">
                    <LatestAndTopNews
                        latestArticles={latestArticles}
                        popularArticles={popularArticles}
                        maxArticle={5}
                    />
                </div>
            </div>
        </div>
    );
}

function RightSidebar({
    onlineOpinionArticleAr,
    speArticleAr,
    speArticleArLead,
    eduArticleAr,
    eduArticleArLead,
}) {
    return (
        <div className="col col-300-1">
            <div className="d-none d-md-block">
                <Ads05 show />
            </div>

            <SidebarOpinion
                title="মতামত"
                col="sidebar-category-news-title"
                articleAr={onlineOpinionArticleAr}
                maxArticle={2}
            />

            <SideBarNewsWithLeadExclusive
                title="বাহান্ন স্পটলাইট"
                articleAr={speArticleAr}
                articleArLead={speArticleArLead}
                topNews
                hideMore
            />
            {/* <div className="sidebar-converter">
                <a href="https://converter.bahannonews.com/" target="_blank" rel="noreferrer">
                    <img src={ConverterImg} alt="Converter" />
                </a>
            </div> */}
            <FbPagePreview />

            <Ads12 show />

            <SideBarNewsWithLeadEducation
                title="শিক্ষা"
                articleAr={eduArticleAr}
                articleArLead={eduArticleArLead}
            />
        </div>
    );
}

function HomeSection01Main({
    leadArticles,
    speArticleAr,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles,
}) {
    const speArticleArLead = speArticleAr[0];
    const eduArticleArLead = eduArticleAr[0];
    return (
        <div className="container">
            <div className="row">
                <Left
                    leadArticles={leadArticles}
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                />

                <RightSidebar
                    onlineOpinionArticleAr={onlineOpinionArticleAr}
                    speArticleAr={speArticleAr}
                    speArticleArLead={speArticleArLead}
                    eduArticleAr={eduArticleAr}
                    eduArticleArLead={eduArticleArLead}
                />
            </div>
        </div>
    );
}

function HomeSection01Lg({
    leadArticles,
    speArticleAr,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles,
}) {
    return (
        <HomeSection01Main
            leadArticles={leadArticles}
            speArticleAr={speArticleAr}
            popularArticles={popularArticles}
            eduArticleAr={eduArticleAr}
            onlineOpinionArticleAr={onlineOpinionArticleAr}
            latestArticles={latestArticles}
        />
    );
}

export default HomeSection01Lg;
