import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/PlayButton';
import Iopt from '../../../utils/Iopt';

function ArticleBodyAr({ artAr }) {
    return (
        <Link to={artAr.link} className="article-list-item link-area">
            <div className="row h-100 gx-2">
                <div className="col-4 align-self-center">
                    <div className="image-area">
                        <img src={Iopt(artAr.image, 120)} alt="im" />
                        <PlayButton show={artAr.having_video} />
                    </div>
                </div>
                <div className="col-8 align-self-center">
                    <div className="text-area">{artAr.title}</div>
                </div>
            </div>
        </Link>
    );
}
export default function BottomNewsList({ title, articles }) {
    const articleAr = Object.values(articles).sort((item1, item2) => {
        if (item1.time_published > item2.time_published) {
            return -1;
        }
        if (item1.time_published < item2.time_published) {
            return 1;
        }

        return 0;
    });
    const artLeadAr = articleAr[0];

    if (!artLeadAr) {
        return null;
    }

    return (
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="bottom-category-news">
                <div className="bottom-category-news-title">
                    <div className="bottom-category-news-title-sub">
                        <Link to={`/${artLeadAr.category}/${artLeadAr.page}/`}>{title}</Link>
                    </div>
                </div>
                <div className="bottom-news-short">
                    <Link to={artLeadAr.link} className="link-area">
                        <div className="bottom-news-short-img image-area">
                            <img src={Iopt(artLeadAr.image, 360)} alt="Img" />
                            <PlayButton show={artLeadAr.having_video} />
                        </div>
                        <div className="bottom-news-short-title link-title">{artLeadAr.title}</div>
                    </Link>
                    <hr style={{ marginTop: 0 }} />
                    {articleAr.slice(1, 5).map((artAr) => (
                        <ArticleBodyAr artAr={artAr} key={artAr.sl} />
                    ))}

                    <div className="bottom-news-footer">
                        <div className="bottom-news-footer-text link-area">
                            <Link
                                to={`/${artLeadAr.category}/${artLeadAr.page}/`}
                                className="link-title"
                            >
                                আরও...
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
