import React from 'react';
import { Outlet } from 'react-router-dom';
import LastUpdateProvider from '../../../contexts/LastUpdateProvider';
import FooterSectionSecond from '../section/FooterSectionSecond';
import HeaderSectionSecond from '../section/HeaderSectionSecond';
import MenuSection from '../section/MenuSection';

function PageCommonHandler() {
    return (
        <LastUpdateProvider>
            <HeaderSectionSecond />
            <MenuSection />

            <Outlet />

            <FooterSectionSecond />
        </LastUpdateProvider>
    );
}

export default PageCommonHandler;
