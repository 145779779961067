import React from 'react';
import BrowserInfo from '../../../utils/BrowserInfo';
import SideBarNewsList from '../components/SideBarNewsList';
import SectionThumb from './SectionThumb';

function HomeSection07MainLg({ title2, articles2 }) {
    return (
        <div className="container">
            <SectionThumb title={title2} articleAr={articles2} />
        </div>
    );
}

function HomeSection07Main({ title1, articles1, title2, articles2 }) {
    return (
        <div className="container">
            <div className="row ">
                <div className="col col-300-1">
                    <SideBarNewsList
                        title={title1}
                        articleAr={articles1}
                        col="sidebar-category-news-title"
                        maxArticle={6}
                    />
                </div>
                <div className="col col-840">
                    <SectionThumb title={title2} articleAr={articles2} />
                </div>
            </div>
        </div>
    );
}

function HomeSection07({ title1, articles1, title2, articles2 }) {
    const { sizeName } = BrowserInfo();

    if (sizeName === 'bs-lg') {
        return <HomeSection07MainLg title2={title2} articles2={articles2} />;
    }

    return (
        <HomeSection07Main
            title1={title1}
            articles1={articles1}
            title2={title2}
            articles2={articles2}
        />
    );
}

export default HomeSection07;
