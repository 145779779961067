import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PageProperties from '../../../models/PageProperties';
import { setActiveMenu } from '../../../utils/Menu';

export default function AdvertisePage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenu('/'));
    }, [dispatch]);

    PageProperties('Advertisements');

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mt-3 mb-3">
                    <div className="home-category-news-title" style={{ width: '100%' }}>
                        <div className="home-category-news-title-sub" style={{ width: 150 }}>
                            <Link to="privacy">বিজ্ঞাপন</Link>
                        </div>
                    </div>
                    <div className="details-news-body" style={{ width: '100%' }}>
                        <div className="details-news-body-detail" style={{ minHeight: 500 }}>
                            <h4>GOOGLE ADSENSE </h4>
                            <p>
                                These third-party ad servers or ad networks use technology to the
                                advertisements and links that appear on
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                send directly to your browsers. They automatically receive your IP
                                address when this occurs. Other technologies may also be used by the
                                third-party ad networks to measure the effectiveness of their
                                advertisements and / or to personalize the advertising content that
                                you see.
                                <br />{' '}
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                has no access to or control over these cookies that are used by
                                third-party advertisers.
                                <br /> You should consult the respective privacy policies of these
                                third-party ad servers for more detailed information on their
                                practices as well as for instructions about how to opt-out of
                                certain practices. <br />
                                If you wish to disable cookies, you may do so through your
                                individual browser options.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
