/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import iconPrev from '../../assets/images/slider-Arrow-Left.svg';
import iconNext from '../../assets/images/slider-Arrow-Right.svg';
import iconExit from '../../assets/images/slider-Close.svg';

const delay = 5000;
const LightBoxContext = createContext();

export function useAddress() {
    return useContext(LightBoxContext);
}

const LightBox = ({ LgItemsSt, currentIndex, setCurrentIndex }) => {
    const totalImage = LgItemsSt.length;

    const handelPrev = useCallback(() => {
        setCurrentIndex((cuState) => {
            if (cuState === 0) return totalImage - 1;
            return cuState - 1;
        });
    }, [setCurrentIndex, totalImage]);

    const handelNext = useCallback(() => {
        setCurrentIndex((cuState) => {
            if (cuState === totalImage - 1) return 0;
            return cuState + 1;
        });
    }, [setCurrentIndex, totalImage]);

    const handelIndex = useCallback(
        (i) => {
            if (i < 0) {
                setCurrentIndex(0);
            } else if (i > totalImage - 1) {
                setCurrentIndex(totalImage - 1);
            } else {
                setCurrentIndex(i - 1);
            }
        },
        [setCurrentIndex, totalImage]
    );

    useEffect(() => {
        const myTimeout = setTimeout(() => {
            setCurrentIndex((cuState) => (cuState + 1) % LgItemsSt.length);
        }, delay);

        return () => {
            clearTimeout(myTimeout);
        };
    }, [LgItemsSt.length, currentIndex, setCurrentIndex]);

    useEffect(() => {
        const initEvent = (ev) => {
            console.log(ev.keyCode);

            if (ev.keyCode === 37) {
                handelPrev();
            }
            if (ev.keyCode === 39) {
                handelNext();
            }

            if (ev.keyCode > 47 && ev.keyCode < 58) {
                handelIndex(ev.keyCode - 48);
            }
        };

        window.addEventListener('keydown', initEvent);

        return () => {
            console.log('Removed');
            window.removeEventListener('keydown', initEvent);
        };
    }, [handelIndex, handelNext, handelPrev, setCurrentIndex]);

    if (totalImage === 0) {
        return null;
    }

    if (currentIndex === -1) {
        return null;
    }
    return (
        <div className="bik-lb">
            <div className="lb-navigator">
                <div className="lb-navigator-counter">
                    {currentIndex + 1}/{totalImage}
                </div>
                <div className="lb-navigator-close">
                    <img
                        src={iconExit}
                        alt="Close"
                        onClick={() => {
                            setCurrentIndex(-1);
                        }}
                    />
                </div>
            </div>
            <div className="lb-sliding">
                {LgItemsSt.map((item) => (
                    <figure key={item.id} className={item.id === currentIndex ? 'active' : ''}>
                        <img src={item.src} alt="" />
                        <figcaption>{item.caption}</figcaption>
                    </figure>
                ))}
            </div>
            {totalImage > 1 ? (
                <div className="lb-navigator-custom">
                    <div className="lb-navigator-left">
                        <img src={iconPrev} alt="Prev" onClick={handelPrev} />
                    </div>
                    <div className="lb-navigator-right">
                        <img src={iconNext} alt="Next" onClick={handelNext} />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

function LightBoxProvider({ children }) {
    const refHtml = useRef(null);

    const [LgItemsSt, SetLgItemsSt] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        const lbItems = [];
        const targetHtml = refHtml.current.querySelectorAll('figure.image');

        targetHtml.forEach((item, i) => {
            lbItems.push({
                id: i,
                src: item.querySelector('img').src,
                thumb: item.querySelector('img').src,
                caption: item.querySelector('figcaption')?.innerHTML || null,
            });

            item.addEventListener('click', () => {
                console.log('I love js');

                setCurrentIndex(i);
            });
        });

        SetLgItemsSt(lbItems);
    }, []);

    const value = { currentIndex, totalImage: LgItemsSt.length };

    return (
        <LightBoxContext.Provider value={value}>
            <div ref={refHtml}>{children}</div>
            {currentIndex > -1 ? (
                <LightBox
                    LgItemsSt={LgItemsSt}
                    SetLgItemsSt={SetLgItemsSt}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            ) : null}
        </LightBoxContext.Provider>
    );
}

export default LightBoxProvider;
