/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal } from 'react-bootstrap';

function VideoPlayModal({ show, setShowVideo }) {
    return (
        <Modal
            show={show}
            onHide={() => setShowVideo(false)}
            dialogClassName="dialog-modal-video"
            contentClassName="modal-content-video"
            backdropClassName="modal-backdrop-video"
            centered
        >
            <div className="modal-content modal-content-video">
                <Modal.Body className="modal-body-video">
                    <div className="modal-close-video" onClick={() => setShowVideo(false)} />
                    <div className="video-responsive">
                        <iframe
                            className="modal-responsive-video"
                            src="https://www.youtube.com/embed/ITMJYHWQ2KM"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default VideoPlayModal;
