import React from 'react';
import TimestampToDate from '../../../models/TimestampToDate';

function FlowSidebarSection({ latestArticles }) {
    return (
        <div className="col col-300-1 not-print-class">
            <div className="flow-sidebar-title">মূল বিষয়গুলি</div>
            <div id="content">
                <ul className="timeline">
                    {latestArticles?.map((row) => (
                        <li className="event" key={row.sl}>
                            <p>
                                <TimestampToDate time={row.time_published} />
                            </p>
                            <h3>{row.title}</h3>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default FlowSidebarSection;
