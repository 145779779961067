import React from 'react';
import BrowserInfo from '../../../utils/BrowserInfo';
import BottomNewsList from '../components/BottomNewsList';

function HomeSection09Lg({ articles1, title1, articles2, title2, articles3, title3 }) {
    const { sizeName } = BrowserInfo();
    if (sizeName === 'bs-lg') {
        return (
            <div className="container">
                <div className="row">
                    <BottomNewsList title={title1} articles={articles1} />
                    <BottomNewsList title={title2} articles={articles2} />
                    <BottomNewsList title={title3} articles={articles3} />
                </div>
            </div>
        );
    }

    return null;
}

export default HomeSection09Lg;
