import { useEffect, useState } from 'react';

const loadPageOn = 300; // px

function MultiPageLoader({ defPage, pages = [] }) {
    // const [index, setIndex] = useState(0);
    const [loadedPages, setLoadedPage] = useState([defPage]);

    useEffect(() => {
        const loadPage = () => {
            setLoadedPage((val) => {
                const newPage = pages[val.length - 1] || null;
                if (newPage) {
                    val.push(newPage);
                }
                return [...val];
            });
        };

        const onScroll = (ev) => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.offsetHeight;
            const topPosition = ev?.target?.documentElement?.scrollTop || 0;

            if (loadPageOn + windowHeight + topPosition > documentHeight) {
                loadPage();
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [pages]);

    return (
        <div>
            {loadedPages.map((page) => (
                <div key={page?.props?.keyName}>{page}</div>
            ))}
        </div>
    );
}

export default MultiPageLoader;
