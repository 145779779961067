import React, { useEffect, useRef, useState } from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import { Link } from 'react-router-dom';
import { Ads12, Ads20 } from '../../../components/Ads';
import PlayButton from '../../../components/PlayButton';
import InitInfo from '../../../utils/InitInfo';
import Iopt from '../../../utils/Iopt';
import HeroAndUpdatedNews from '../components/HeroAndUpdatedNews';
import SideBarNewsWithLeadExclusive from '../components/SideBarNewsWithLeadExclusive';
import SidebarOpinion from '../components/SidebarOpinion';
import SpecialBoxNews from '../components/SpecialBoxNews';

const HeroNewsSort = ({ article }) => {
    if (!article) {
        return null;
    }

    return (
        <Link to={article.link} className="hero-news-short link-area">
            <div className="hero-news-short-img image-area">
                <img src={Iopt(article.image, 320)} alt={article.title} />
                <PlayButton show={article.having_video} />
            </div>
            <div className="hero-news-short-title link-title">{article.title}</div>
        </Link>
    );
};

const HeroNewsSortRow = ({ article1, article2 }) => (
    <div className="row">
        <div className="col-6">
            <HeroNewsSort article={article1} />
        </div>
        <div className="col-6">
            <HeroNewsSort article={article2} />
        </div>
    </div>
);

function FbPagePreview() {
    const { configs } = InitInfo();
    const [fbPageWidth, setFbPageWidth] = useState(0);

    const ref = useRef();
    useEffect(() => {
        // ref
        // console.dir(ref.current.clientWidth);
        setFbPageWidth(ref?.current.clientWidth);
    }, []);

    return (
        <div style={{ marginBottom: '30px' }} ref={ref}>
            {fbPageWidth > 200 ? (
                <FacebookProvider appId={configs.fbAppId}>
                    <Page href={configs.facebook} width={fbPageWidth} />
                </FacebookProvider>
            ) : null}
        </div>
    );
}

function Left({ leadArticles, latestArticles, popularArticles }) {
    return (
        <div className="col col-840">
            <div>
                <HeroAndUpdatedNews
                    heroNewsArticle={leadArticles[0]}
                    leadArticles={leadArticles}
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                />

                <HeroNewsSortRow article1={leadArticles[1]} article2={leadArticles[2]} />

                <HeroNewsSortRow article1={leadArticles[3]} article2={leadArticles[4]} />

                <HeroNewsSortRow article1={leadArticles[5]} article2={leadArticles[6]} />

                <HeroNewsSortRow article1={leadArticles[7]} article2={leadArticles[8]} />
            </div>
        </div>
    );
}

function RightSidebar({
    onlineOpinionArticleAr,
    speArticleAr,
    speArticleArLead,
    // eduArticleAr,
    // eduArticleArLead,
}) {
    return (
        <>
            <div className="col-6">
                <SidebarOpinion
                    title="মতামত"
                    col="sidebar-category-news-title"
                    articleAr={onlineOpinionArticleAr}
                    maxArticle={2}
                />

                <Ads20 show />
            </div>

            <div className="col-6">
                <SideBarNewsWithLeadExclusive
                    title="বাহান্ন স্পটলাইট"
                    articleAr={speArticleAr}
                    articleArLead={speArticleArLead}
                    topNews
                    hideMore
                />
                {/* <div className="sidebar-converter">
                    <a href="https://converter.bahannonews.com/" target="_blank" rel="noreferrer">
                        <img src={ConverterImg} alt="Converter" />
                    </a>
                </div> */}

                <Ads12 show />

                <FbPagePreview />
            </div>
        </>
    );
}

function HomeSection01Main({
    leadArticles,
    speArticleAr,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles,
}) {
    const speArticleArLead = speArticleAr[0];
    const eduArticleArLead = eduArticleAr[0];

    return (
        <div className="container">
            <div className="row">
                <Left
                    leadArticles={leadArticles}
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                />

                <RightSidebar
                    onlineOpinionArticleAr={onlineOpinionArticleAr}
                    speArticleAr={speArticleAr}
                    speArticleArLead={speArticleArLead}
                    eduArticleAr={eduArticleAr}
                    eduArticleArLead={eduArticleArLead}
                />
                <div className="col-12">
                    <div className="special-feature">
                        <div className="sidebar-category-news-title-edu">
                            <div className="sidebar-category-news-title-edu-sub">
                                <Link to="/online/education/">শিক্ষা</Link>
                            </div>
                        </div>
                        <div className="row special-feature-padding">
                            {Object.values(eduArticleAr)
                                .sort((item1, item2) => {
                                    if (item1.time_published > item2.time_published) {
                                        return -1;
                                    }
                                    if (item1.time_published < item2.time_published) {
                                        return 1;
                                    }

                                    return 0;
                                })
                                .slice(0, 4)
                                .map((row) => (
                                    <div className="col-6">
                                        <SpecialBoxNews article={row} key={row.sl} />
                                    </div>
                                ))}
                            <div className="col-12 special-feature-footer">
                                <Link to="online/education">আরও...</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function HomeSection01Md({
    leadArticles,
    speArticleAr,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles,
}) {
    return (
        <HomeSection01Main
            leadArticles={leadArticles}
            speArticleAr={speArticleAr}
            popularArticles={popularArticles}
            eduArticleAr={eduArticleAr}
            onlineOpinionArticleAr={onlineOpinionArticleAr}
            latestArticles={latestArticles}
        />
    );
}

export default HomeSection01Md;
