import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import userProfileImage from '../../../../assets/images/user.png';
import { useAuth } from '../../../contexts/AuthProvider';
import Iopt from '../../../utils/Iopt';

function UserSidebarSection() {
    const { pathname } = useLocation();
    const { currentUser } = useAuth();

    return (
        <div className="col-300-1">
            <div className="user-sidebar">
                <div className="user-sidebar-profile">
                    <div className="user-sidebar-profile-img">
                        <img
                            src={Iopt(
                                currentUser?.photoUrl
                                    ? Iopt(currentUser.photoUrl)
                                    : userProfileImage,
                                128
                            )}
                            alt="User"
                        />
                    </div>
                    <div className="user-sidebar-profile-title">
                        <h3>{currentUser.displayName}</h3>
                        <p>
                            {currentUser.phoneNumber ? currentUser.phoneNumber : currentUser.email}
                        </p>
                    </div>
                </div>
                <div className="user-sidebar-divider" />

                <div className="user-sidebar-menu">
                    <Link
                        to="/user/profile/"
                        className={
                            pathname === '/user/profile/' ? 'user-menu menu-active' : 'user-menu'
                        }
                    >
                        <div className="user-menu-profile-img" />
                        <div className="user-menu-profile-text">My Profile</div>
                    </Link>
                    <Link
                        to="/user/comments/"
                        className={
                            pathname === '/user/comments/' ? 'user-menu menu-active' : 'user-menu'
                        }
                    >
                        <div className="user-menu-comment-img" />
                        <div className="user-menu-comment-text">Comment History</div>
                    </Link>
                    <Link
                        to="/user/save-news/"
                        className={
                            pathname === '/user/save-news/' ? 'user-menu menu-active' : 'user-menu'
                        }
                    >
                        <div className="user-menu-save-img" />
                        <div className="user-menu-save-text">Saved News</div>
                    </Link>
                    <Link
                        to="/user/history/"
                        className={
                            pathname === '/user/history/' ? 'user-menu menu-active' : 'user-menu'
                        }
                    >
                        <div className="user-menu-browse-img" />
                        <div className="user-menu-browse-text">Browsing History</div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default UserSidebarSection;
