import React from 'react';
import Logo from '../../assets/images/bahanno-logo.svg';

function EmptyPlaceholder() {
    return (
        <div className="em-place-body">
            <img src={Logo} alt="Logo" />
        </div>
    );
}

export default EmptyPlaceholder;
