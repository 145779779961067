function ConvertStringBn(str) {
    const pre = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'AM',
        'PM',

        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',

        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Ma',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',

        'Saturday',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',

        'Sat',
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
    ];
    const post = [
        '০',
        '১',
        '২',
        '৩',
        '৪',
        '৫',
        '৬',
        '৭',
        '৮',
        '৯',
        // 'পূর্বাহ্ন',
        // 'অপরাহ্ন',
        'এ.এম.',
        'পি.এম.',

        'জানুয়ারী',
        'ফেব্রুয়ারী',
        'মার্চ',
        'এপ্রিল',
        'মে',
        'জুন',
        'জুলাই',
        'আগস্ট',
        'সেপ্টেম্বর',
        'অক্টোবর',
        'নভেম্বর',
        'ডিসেম্বর',

        'জানুয়ারী',
        'ফেব্রুয়ারী',
        'মার্চ',
        'এপ্রিল',
        'মে',
        'জুন',
        'জুলাই',
        'আগস্ট',
        'সেপ্টেম্বর',
        'অক্টোবর',
        'নভেম্বর',
        'ডিসেম্বর',

        'শনিবার',
        'রোববার',
        'সোমবার',
        'মঙ্গলবার',
        'বুধবার',
        'বৃহস্পতিবার',
        'শুক্রবার',

        'শনি',
        'রবি',
        'সোম',
        'মঙ্গল',
        'বুধ',
        'বৃহঃ',
        'শুক্র',
    ];

    let newStr = str;

    pre.forEach((x, i) => {
        newStr = newStr.split(x).join(post[i]);
    });

    return newStr;
}

export default ConvertStringBn;
