import store from '../store';

function InitInfo() {
    const storeStates = store.getState();

    return {
        storeStates,

        initData: storeStates?.initData,

        categoryAr: storeStates?.siteData?.categoryAr,

        categoryPageIds: storeStates?.siteData?.categoryPageIds,

        latestArticles: storeStates?.siteData?.latestArticles,

        configs: storeStates?.siteData?.configs,

        activeMenu: storeStates?.activeMenu,

        wcBanner: storeStates?.welcomeBanner,

        // topAdd: storeStates?.topAdd,
    };
}

export default InitInfo;
