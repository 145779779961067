import React from 'react';
import BrowserInfo from '../../../utils/BrowserInfo';
import BottomNewsList from '../components/BottomNewsList';

function HomeSection11Md({ articles1, title1, articles2, title2 }) {
    const { sizeName } = BrowserInfo();
    if (sizeName === 'bs-md') {
        return (
            <div className="container">
                <div className="row">
                    <BottomNewsList title={title1} articles={articles1} />
                    <BottomNewsList title={title2} articles={articles2} />
                </div>
            </div>
        );
    }

    return null;
}

export default HomeSection11Md;
