import React from 'react';
import { Link } from 'react-router-dom';
import CmtImg from '../../../../assets/images/commentImg.png';

function UserProfileBrowsePage() {
    return (
        <div className="col col-840">
            <div className="user-profile-title">
                <h5>Browsing History</h5>
                <div className="user-profile-title-div">
                    <input className="form-control" type="date" />
                </div>
                <div className="user-profile-title-div">
                    <button type="button">Clear History</button>
                </div>
            </div>
            <hr />
            <div className="user-profile-history">
                <div className="user-profile-history-title">
                    <h4>Today</h4>
                    <hr />
                </div>
                <div className="user-profile-history-list">
                    <ul>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />

                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />
                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />
                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="user-profile-history">
                <div className="user-profile-history-title">
                    <h4>Yesterday</h4>
                    <hr />
                </div>
                <div className="user-profile-history-list">
                    <ul>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />

                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />
                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />
                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="user-profile-history">
                <div className="user-profile-history-title">
                    <h4>সোমবার, ০৭ মে ২০২২</h4>
                    <hr />
                </div>
                <div className="user-profile-history-list">
                    <ul>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />

                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />
                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                        <li>
                            <h6>10.00 PM</h6>
                            <img src={CmtImg} alt="" />
                            <Link to="/user/profile/">
                                ই-রিসোর্স ব্যবহার বিষয়ে এনডিএলআই’র সাথে সমঝোতা স্মারক সই করবে ইউজিসি
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default UserProfileBrowsePage;
