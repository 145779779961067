import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReporterIcon from '../../../../assets/images/icon.png';
import ConfigApi from '../../../../configs/ConfigApi';
import PageProperties from '../../../models/PageProperties';
import DetailsPlaceholder from '../../../placeholders/DetailsPlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/Menu';
import MultiPageLoader from '../../../utils/MultiPageLoader';
import DefaultSidebarSection from '../section/DefaultSidebarSection';
import DetailsSection01 from '../section/DetailsSection01';
import NotFoundSection from '../section/NotFoundSection';

function DetailsLoader({ sl }) {
    const [articleData, setArticleData] = useState(null);
    const article = articleData?.articleDetails;

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                articleDetails: sl,
            },
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [sl]);

    if (!article) {
        return <DetailsPlaceholder />;
    }

    return (
        <>
            <div style={{ display: 'flex', marginBottom: 30 }}>
                <div style={{ flex: '0 0 auto', width: '45.835%' }}>
                    <hr style={{ marginBottom: 30 }} />
                </div>
                <div
                    className="col-2"
                    style={{ textAlign: 'center', flex: '0 0 auto', width: '8.33%' }}
                >
                    <img
                        src={ReporterIcon}
                        style={{ width: 40, height: 40, borderRadius: 100 }}
                        alt=""
                    />
                </div>
                <div
                    className="col-5"
                    style={{ textAlign: 'center', flex: '0 0 auto', width: '45.835%' }}
                >
                    <hr style={{ marginBottom: 30 }} />
                </div>
            </div>
            <DetailsSection01 article={article} isHeaderTitle={false} />
        </>
    );
}

function DetailsControl({ article }) {
    return (
        <>
            <MultiPageLoader
                defPage={<DetailsSection01 article={article} keyName={article.sl} />}
                pages={article?.articles_this_page?.map((articleSl) => (
                    <DetailsLoader sl={articleSl} keyName={articleSl} />
                ))}
            />
        </>
    );
}

function DetailPageControl() {
    const dispatch = useDispatch();
    const { articleId } = useParams();
    const [articleData, setArticleData] = useState(null);
    const article = articleData?.articleDetails;
    const page = article?.page || '/';

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                articleDetails: articleId,
            },
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [articleId]);

    useEffect(() => {
        const pageId = page || 'na';
        if (pageId) {
            dispatch(setActiveMenu(pageId));
        }
    }, [dispatch, page]);

    PageProperties(article?.title || '');

    if (articleData === null) {
        return <DetailsPlaceholder />;
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col col-840">
                    {article ? <DetailsControl article={article} /> : <NotFoundSection />}
                </div>

                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function DetailPage() {
    const { articleId } = useParams();
    return <DetailPageControl key={articleId} />;
}
