import { useEffect, useState } from 'react';
import { Gpt, GptScript } from 'react-gpt-ads';
import adsChancellor from '../../assets/images/ads-chancellor-01.gif';
import adsKbl from '../../assets/images/ads-kbl-01.gif';
import ads2 from '../../assets/images/bikiran-add-2.png';
import ads1 from '../../assets/images/bikiran-banner-1.png';
import ads20 from '../../assets/images/bik_add.jpg';
import adBsb from '../../assets/images/Cambrian_300_300.png';
import adsTwelve from '../../assets/images/onnorokom_300_250.gif';
import adsSeventeen from '../../assets/images/onnorokom_728_90.gif';
import adsEleven from '../../assets/images/onnorokom_900.gif';
import adEight from '../../assets/images/Panjeree-1.png';
import adsTen1 from '../../assets/images/Panjeree-2.gif';
import adsFive2 from '../../assets/images/Panjeree-4.gif';
import adsFive from '../../assets/images/trust-clg-03.jpg';
import adsFive1 from '../../assets/images/trust-clg-04.jpg';
// import adsTen from '../../assets/images/trust_1300_120_1.jpg';
import adsTen from '../../assets/images/Trust_970_90.gif';

// https://www.milestonecollege.com/

export function Ads01({ show = false }) {
    if (!show) {
        return null;
    }

    return (
        <div className="ads-01 d-lg-none">
            <a href="https://sheikhrussel.gov.bd/" target="_blank" rel="noreferrer">
                <img
                    src="https://www.bahannonews.com/assets/images/ads-rasel-03.gif"
                    alt="www.bahannonews.com"
                />
            </a>
        </div>
    );
}

export function Ads02({ show = false }) {
    if (!show) {
        return null;
    }

    return (
        <div className="col-12 d-none d-lg-block d-xl-block">
            <div className="ad-one">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        </div>
    );
}

export function Ads03({ show = false }) {
    if (!show) {
        return null;
    }
    return (
        <div className="ads-01 d-none d-lg-block">
            <a href="https://sheikhrussel.gov.bd/" target="_blank" rel="noreferrer">
                <img
                    src="https://www.bahannonews.com/assets/images/ads-rasel-01.gif"
                    alt="www.bahannonews.com"
                />
            </a>
        </div>
    );
}

export function Ads04({ show = false }) {
    if (!show) {
        return null;
    }
    return (
        <div className="ads-01" style={{ marginTop: '-10px' }}>
            <a href="https://sheikhrussel.gov.bd/" target="_blank" rel="noreferrer">
                <img
                    src="https://www.bahannonews.com/assets/images/ads-rasel-02.gif"
                    alt="www.bahannonews.com"
                    style={{ width: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads05({ show = false, mode }) {
    const images = [adBsb, adsFive2];
    const link = ['http://www.bsbbd.com', 'https://pbs.com.bd'];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 4000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads06({ show = false, mode }) {
    const images = [adsChancellor, adsChancellor];
    const link = ['https://alfatahbd.com/', 'https://alfatahbd.com/'];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads061({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one">
            <div id="gpt-passback6">
                <Gpt
                    adUnit="/21633305394/Hyper_Local_300x250"
                    name="gpt-passback6"
                    size={[300, 250]}
                />
            </div>
        </div>
    );
}
export function Ads07({ show = false, mode, name = 'gpt-passback7' }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ads-seven">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="ads-seven">
            <GptScript />
            <div id={name}>
                <Gpt adUnit="/21633305394/Hyper_Local_728x90" name={name} size={[728, 90]} />
            </div>
        </div>
    );
}
export function Ads08({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-eight not-print-class">
            <a href="https://cutt.ly/hI9hCu4" target="_blank" rel="noreferrer">
                <img
                    src={adEight}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads09({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-eight not-print-class">
            {/* <a href="https://trustcollege.edu.bd/" target="_blank" rel="noreferrer">
                <img
                    src={adsTrust}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a> */}
            {/* <a href="http://www.anupameducation.com/" target="_blank" rel="noreferrer">
                <img
                    src={adsKbl}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a> */}
            <a href="http://www.anupameducation.com/" target="_blank" rel="noreferrer">
                <img
                    src={adsKbl}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads10({ show = false, mode }) {
    const images = [adsTen1, adsEleven];
    const link = [
        'https://www.facebook.com/PanjereeKids',
        'https://m.me/BigganBaksho?ref=OnlinePortal',
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: 129 }}
                />
            </a>
        </div>
    );
}
export function Ads11({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one" style={{ height: '90px' }}>
                <img src={ads1} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsEleven}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads12({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-twelve">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-twelve">
            <div id="gpt-passback6">
                <Gpt
                    adUnit="/21633305394/Hyper_Local_300x250"
                    name="gpt-passback6"
                    size={[300, 250]}
                />
            </div>
        </div>
    );
}
export function Ads13({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads14({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads14Full({ show = false, mode }) {
    const images = [adsTen, adsTen];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two-full" style={{ height: '100px' }}>
                <img src={ads1} alt="www.bahannonews.com" style={{ height: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine not-print-class">
            <a href=" https://trustcollege.edu.bd/" target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads15({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads16({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads17({ show = false, mode }) {
    const images = [adsTen1, adsEleven];
    const link = [
        'https://www.facebook.com/PanjereeKids',
        'https://m.me/BigganBaksho?ref=OnlinePortal',
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 8000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    // style={{ height: '100px' }}
                />
            </a>
        </div>
    );
}
export function Ads18({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsSeventeen}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100px' }}
                />
            </a>
        </div>
    );
}
export function Ads19({ show = false, mode }) {
    const images = [adsFive, adsFive1];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one">
            <a href="http://www.anupameducation.com/" target="_blank" rel="noreferrer">
                <img
                    src={adsKbl}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads20({ show = false, mode }) {
    const images = [adsFive, adsFive1];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-20">
            <a href="http://www.bikiran.com/" target="_blank" rel="noreferrer">
                <img src={ads20} alt="www.bahannonews.com" />
            </a>
        </div>
    );
}

function Ads() {
    return <div />;
}

export default Ads;
