/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { confirm } from 'react-confirm-box';
import ReactTooltip from 'react-tooltip';
import '../../../../assets/css/confirm-styles.css';
import DeleteIcon from '../../../../assets/images/c-delete-icon.svg';
import DisLikeIcon from '../../../../assets/images/c-dislike-icon.svg';
import LikeIcon from '../../../../assets/images/c-like-icon.svg';
import DisLikeIconRed from '../../../../assets/images/co-dislike-color.svg';
import LikeIconRed from '../../../../assets/images/co-like-color.svg';
import reporterIcon from '../../../../assets/images/icon.png';
import IIcon from '../../../../assets/images/iIcon.svg';
import { useAuth } from '../../../contexts/AuthProvider';
import { useComment } from '../../../contexts/CommentProvider';
import TimestampToDate from '../../../models/TimestampToDate';
import Iopt from '../../../utils/Iopt';

function CommentDelete({ commentId, removeComments }) {
    const onClick = async (options) => {
        const result = await confirm('Are you sure?', options);
        if (result) {
            removeComments(commentId);
        }
    };
    return (
        <li className="hide-print">
            <button
                className="hide-print details-commenter-delete-button"
                type="button"
                onClick={onClick}
            >
                <img src={DeleteIcon} alt="" />
            </button>
        </li>
    );
}

function CommentLike({ row, addLike }) {
    return (
        <>
            <li>
                <button
                    type="button"
                    onClick={() => addLike([row?.likeCode === 1 ? 0 : 1, row.commentId])}
                >
                    <img src={row?.likeCode === 1 ? LikeIconRed : LikeIcon} alt="" />
                </button>
            </li>
            <li>{row.likeCount}</li>
            <li>
                <button
                    type="button"
                    onClick={() => addLike([row?.likeCode === -1 ? 0 : -1, row.commentId])}
                >
                    <img src={row?.likeCode === -1 ? DisLikeIconRed : DisLikeIcon} alt="" />
                </button>
            </li>
            <li>{row.dislikeCount}</li>
        </>
    );
}

function CommentField({
    commentText,
    addComments,
    handleCommentText,
    parentSl,
    setInName,
    cusInName,
    setCommentText,
}) {
    const { openLoginModal, currentUser } = useAuth();

    const hanldeClick = () => {
        setInName(cusInName);
        setCommentText('');
        if (currentUser?.email === null) {
            openLoginModal();
        }
    };
    return (
        <div className="header-search-bar hide-print">
            <div className="details-comment-input">
                <div className="details-comment-group">
                    <div className="details-comment-text-input">
                        <textarea
                            placeholder="আপনার মন্তব্যটি লিখুন..."
                            value={commentText}
                            onChange={handleCommentText}
                            onClick={hanldeClick}
                        />
                    </div>

                    <div className="details-comment-post-btn">
                        <button
                            type="button"
                            disabled={!commentText}
                            onClick={() => addComments(parentSl)}
                        >
                            Post
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CommentList({
    row,
    commentText,
    addComments,
    handleCommentText,
    removeComments,
    currentUser,
    addLike,
    inName,
    setInName,
    setCommentText,
}) {
    const [replayField, setReplayField] = useState(false);

    return (
        <>
            <div className="col-1 mt-4">
                <div className="details-reporter-img" style={{ paddingTop: 5 }}>
                    <img
                        src={row?.profileImage ? Iopt(row?.profileImage) : reporterIcon}
                        style={{ width: 40, height: 40 }}
                        alt="Reporter"
                    />
                </div>
            </div>
            <div className="col-11 mt-4 details-comment-sub">
                <div className="details-commenter-name">
                    {row.profileName}
                    <small>
                        <TimestampToDate time={row.time} />
                    </small>
                </div>
                <div className="details-comment">
                    {row.comment}
                    {row.status === 'unpublished' ? (
                        <>
                            <img
                                src={IIcon}
                                alt=""
                                style={{ marginLeft: 10 }}
                                data-for="copyTip"
                                data-tip
                            />
                            <ReactTooltip id="copyTip" place="top" effect="solid">
                                Unpublished Comment
                            </ReactTooltip>
                        </>
                    ) : (
                        ''
                    )}
                </div>
                <div className="details-comment-option hide-print">
                    <ul>
                        <CommentLike row={row} addLike={addLike} />
                        <li>
                            <button
                                type="button"
                                onClick={() => {
                                    setReplayField(true);
                                    setInName('child');
                                }}
                            >
                                Reply
                            </button>
                        </li>
                        {currentUser.email === row.profileEmail ? (
                            <CommentDelete
                                commentId={row.commentId}
                                removeComments={removeComments}
                            />
                        ) : (
                            ''
                        )}
                    </ul>
                </div>
            </div>
            <div className="col-1 mt-2" />
            <div className="col-11 mt-2">
                {replayField ? (
                    <CommentField
                        commentText={inName === 'child' ? commentText : ''}
                        addComments={addComments}
                        handleCommentText={handleCommentText}
                        parentSl={row.commentId}
                        setInName={setInName}
                        cusInName="child"
                        setCommentText={setCommentText}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
}

function CommentListChild({ row, removeComments, currentUser, addLike }) {
    return (
        <>
            <div className="col-1 mt-4" />
            <div className="col-1 mt-4">
                <div className="details-reporter-img" style={{ paddingTop: 5 }}>
                    <img
                        src={row?.profileImage ? Iopt(row?.profileImage) : reporterIcon}
                        style={{ width: 40, height: 40 }}
                        alt="Reporter"
                    />
                </div>
            </div>
            <div className="col-10 mt-4 details-comment-sub">
                <div className="details-commenter-name">
                    {row.profileName}
                    <small>
                        <TimestampToDate time={row.time} />
                    </small>
                </div>
                <div className="details-comment">
                    {row.comment}
                    {row.status === 'unpublished' ? (
                        <>
                            <img src={IIcon} alt="" style={{ marginLeft: 10 }} />
                            <ReactTooltip id="copyTip" place="top" effect="solid">
                                Unpublished Comment
                            </ReactTooltip>
                        </>
                    ) : (
                        ''
                    )}
                </div>
                <div className="details-comment-option">
                    <ul>
                        <CommentLike row={row} addLike={addLike} />

                        {currentUser.email === row.profileEmail ? (
                            <CommentDelete
                                commentId={row.commentId}
                                removeComments={removeComments}
                            />
                        ) : (
                            ''
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}

function CommentSection() {
    const {
        comments,
        addComments,
        removeComments,
        commentText,
        setCommentText,
        addLike,
        currentUser,
        inName,
        setInName,
    } = useComment();

    const handleCommentText = (ev) => {
        setCommentText(ev.target.value);
    };

    return (
        <div className="row details-comment-info">
            <div className="col-7">
                <div className="details-share-text">মন্তব্য করুন</div>
            </div>

            <div className="col-12">
                <hr />
            </div>
            <div className="col-1 hide-print">
                <div className="details-reporter-img ">
                    <img
                        src={currentUser?.photoUrl ? Iopt(currentUser?.photoUrl) : reporterIcon}
                        style={{ width: 50, height: 50 }}
                        alt="User"
                    />
                </div>
            </div>

            <div className="col-11 hide-print">
                <CommentField
                    commentText={inName === 'main' ? commentText : ''}
                    addComments={addComments}
                    handleCommentText={handleCommentText}
                    parentSl={0}
                    setInName={setInName}
                    cusInName="main"
                    setCommentText={setCommentText}
                />
            </div>
            {comments &&
                comments?.map((row) => (
                    <React.Fragment key={row.commentId}>
                        {row.status === 'active' ? (
                            <CommentList
                                row={row}
                                commentText={commentText}
                                addComments={addComments}
                                handleCommentText={handleCommentText}
                                removeComments={removeComments}
                                currentUser={currentUser}
                                addLike={addLike}
                                inName={inName}
                                setInName={setInName}
                                setCommentText={setCommentText}
                            />
                        ) : (
                            ''
                        )}
                        {row.status === 'unpublished' && currentUser.email === row.profileEmail ? (
                            <CommentList
                                row={row}
                                commentText={commentText}
                                addComments={addComments}
                                handleCommentText={handleCommentText}
                                removeComments={removeComments}
                                currentUser={currentUser}
                                addLike={addLike}
                                inName={inName}
                                setInName={setInName}
                                setCommentText={setCommentText}
                            />
                        ) : (
                            ''
                        )}
                        {row?.childes?.map((cRow) => (
                            <React.Fragment key={cRow.commentId}>
                                {cRow.status === 'active' ? (
                                    <CommentListChild
                                        row={cRow}
                                        removeComments={removeComments}
                                        key={cRow.commentId}
                                        currentUser={currentUser}
                                        addLike={addLike}
                                    />
                                ) : (
                                    ''
                                )}
                                {cRow.status === 'unpublished' &&
                                currentUser.email === cRow.profileEmail ? (
                                    <CommentListChild
                                        row={cRow}
                                        removeComments={removeComments}
                                        key={cRow.commentId}
                                        currentUser={currentUser}
                                        addLike={addLike}
                                    />
                                ) : (
                                    ''
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            {/* <div className="col-1 mt-4">
                <div className="details-reporter-img">
                    <img src={ReporterIcon} style={{ width: 40, height: 40 }} alt="Reporter" />
                </div>
            </div>
            <div className="col-11 mt-4">
                <div className="details-commenter-name">
                    H Moni <small>24 Mins ago</small>
                </div>
                <div className="details-comment">
                    আতাউর রহমান সিলেটের গোলাপগঞ্জ উপজেলার বাসিন্দা ছিলেন।
                </div>
            </div> */}
        </div>
    );
}

export default CommentSection;
