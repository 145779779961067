import React from 'react';
import { Link } from 'react-router-dom';
import Iopt from '../utils/Iopt';
import PlayButton from './PlayButton';

export default function HeroNews({ article }) {
    if (!article) {
        return null;
    }

    return (
        <div className="hero-news">
            <Link to={article.link} className="hero-news-body link-area">
                <div className="image-area">
                    <img src={Iopt(article.image, 600)} alt={article.title} />
                    <PlayButton show={article.having_video} />
                </div>
                <div className="hero-news-body-text">
                    <h1 className="hero-news-body-title link-title">{article.title}</h1>
                    <div className="hero-news-body-detail">{article.summery}</div>
                </div>
            </Link>
        </div>
    );
}
