import React from 'react';
import ThreeTabNews from '../../../components/ThreeTabNews';
import SidebarCalendar from '../components/SidebarCalendar';

function DefaultSidebarSectionMd({ articles1, title1, articles2, title2, articles3, title3 }) {
    return (
        <div className="col">
            <div className="row">
                <div className="col-6">
                    <ThreeTabNews
                        articles1={articles1}
                        title1={title1}
                        articles2={articles2}
                        title2={title2}
                        articles3={articles3}
                        title3={title3}
                    />
                </div>
                <div className="col-6">
                    <SidebarCalendar />
                </div>
            </div>
        </div>
    );
}

export default DefaultSidebarSectionMd;
