import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';

function ReactGaSection() {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    const { configs } = InitInfo();

    useEffect(() => {
        if (configs.googleAnalyticsId) {
            // if (!window.location.href.includes("localhost")) {
            ReactGA.initialize(configs.googleAnalyticsId);
            // }
            setInitialized(true);
        }
    }, [configs.googleAnalyticsId]);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);

            // {<!-- Facebook Pixel Code -->
            //     <script>
            //     !function(f,b,e,v,n,t,s)
            //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            //     n.queue=[];t=b.createElement(e);t.async=!0;
            //     t.src=v;s=b.getElementsByTagName(e)[0];
            //     s.parentNode.insertBefore(t,s)}(window, document,'script',
            //     'https://connect.facebook.net/en_US/fbevents.js');
            //     fbq('init', '998797914013739');
            //     fbq('track', 'PageView');
            //     </script>
            //     <noscript><img height="1" width="1" style="display:none"
            //     src="https://www.facebook.com/tr?id=998797914013739&ev=PageView&noscript=1"
            //     /></noscript>
            //     <!-- End Facebook Pixel Code -->}
        }
    }, [initialized, location]);

    return <></>;
}

export default ReactGaSection;
