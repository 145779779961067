import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageCommonHandler from './app/system/models/PageCommonHandler';
import AdvertisePage from './app/system/pages/AdvertisePage';
import AllNewsPage from './app/system/pages/AllNewsPage';
import ArchivePage from './app/system/pages/ArchivePage';
import CategoryPage from './app/system/pages/CategoryPage';
import ContactPage from './app/system/pages/ContactPage';
import DetailPage from './app/system/pages/DetailPage';
import DetailPageFlow from './app/system/pages/DetailPageFlow';
import IndexPage from './app/system/pages/IndexPage';
import NotFoundPage from './app/system/pages/NotFoundPage';
import PrivacyPage from './app/system/pages/PrivacyPage';
import SearchPage from './app/system/pages/SearchPage';
import TermsPage from './app/system/pages/TermsPage';
import UserAuthHandler from './app/users/modals/UserAuthHandler';
import UserProfileBrowsePage from './app/users/pages/UserProfileBrowsePage';
import UserProfileCommentPage from './app/users/pages/UserProfileCommentPage';
import UserProfilePage from './app/users/pages/UserProfilePage';
import UserProfileSaveNewsPage from './app/users/pages/UserProfileSaveNewsPage';
import UsersLogoutPage from './app/users/pages/UsersLogoutPage';
import AppInitDispatch from './dispatches/AppInitDispatch';
import EmptyPlaceholder from './placeholders/EmptyPlaceholder';
import InitInfo from './utils/InitInfo';

function RouteHandler() {
    const dispatch = useDispatch();
    const { initData } = InitInfo();
    const [show, setShow] = useState(!!initData?.initId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    if (!show) {
        return <EmptyPlaceholder />;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageCommonHandler />}>
                    <Route path="/" element={<IndexPage />} />

                    <Route path="/details/article/:articleId/:title/" element={<DetailPage />} />
                    <Route
                        path="/details-flow/article/:articleId/:title/"
                        element={<DetailPageFlow />}
                    />
                    <Route path="/search/" element={<SearchPage />} />

                    <Route path="/terms-condition/" element={<TermsPage />} />

                    <Route path="/not-found/" element={<NotFoundPage />} />

                    <Route path="/privacy-policy/" element={<PrivacyPage />} />

                    <Route path="/contact/" element={<ContactPage />} />

                    <Route path="/advertisement/" element={<AdvertisePage />} />

                    <Route path="/archive/:date/" element={<ArchivePage />} />

                    <Route path="/online/:pageId" element={<CategoryPage />} />

                    <Route path="/news/all/" element={<AllNewsPage />} />
                </Route>

                <Route path="/user/*" element={<UserAuthHandler />}>
                    <Route path="profile/" element={<UserProfilePage />} />
                    <Route path="comments/" element={<UserProfileCommentPage />} />
                    <Route path="save-news/" element={<UserProfileSaveNewsPage />} />
                    <Route path="history/" element={<UserProfileBrowsePage />} />
                    <Route path="logout/" element={<UsersLogoutPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default RouteHandler;
