/* eslint-disable no-unused-vars */
import React from 'react';
import ConvertStringBn from '../utils/ConvertStringBn';

function TimestampToDate({ time }) {
    const newDate = new Date(time * 1000);
    const [day, month, date, year] = newDate.toDateString().split(' ');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');
    let hours = newDate.getHours();
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;
    hours = hours.toString().padStart(2, '0');
    const amPm = newDate.toLocaleTimeString('en-BD').split(' ')[1];

    return (
        <span>{ConvertStringBn(`${date} ${month} ${year}, ${hours} : ${minutes} ${amPm}`)}</span>
    );
}

export default TimestampToDate;
