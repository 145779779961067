export function setActiveMenu(payload) {
    return {
        type: 'ACTIVE_MENU_SET',
        payload,
    };
}

function Menu() {
    return null;
}

export default Menu;
